import { Tooltip, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Legend } from 'recharts';
import { getState } from '../../redux/actions/state';
import './Dashboard.css';
const Dashboard = () => {
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  const listState = useSelector((state) => state.categories.state);
  const chartMode = useSelector((state) => state.categories.chartMode);
  const dispatch = useDispatch();
  const [statut, setStatut] = React.useState();

  React.useEffect(() => {
    if (dateFilter) {
      dispatch(getState(userId, dateFilter?.startDate, dateFilter?.endDate));
    }
  }, [dateFilter]);

  const CustomLegend = ({ payload }) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div className="legendFlex">
          {payload.map((entry, index) => (
            <div key={`item-${index}`} className="legend">
              <li style={{ color: entry.color }}>{entry.value === 'totalTreso' ? 'Solde Trésorerie' : 'Solde Comptable'}</li>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const options = [
    { label: 'Prévisionnel', value: 1 },
    { label: 'Engage', value: 2 },
    { label: 'Pointe', value: 3 }
  ];
  const optionsCompta = [
    { label: 'Simulation', value: 'Simulation' },
    { label: 'Relle', value: 'Relle' }
  ];

  const handleChangeStatut = (value) => {
    setStatut(value);
    dispatch(getState(userId, dateFilter?.startDate, dateFilter?.endDate, value));
  };

  return (
    <div>
      <div className="filterSoldeChart">
        <div>{chartMode === 'treso' ? <span className="spanMode">Mode Trésorerie</span> : <span className="spanMode">Mode Comptabilité</span>}</div>
        <div>
          <Select
            defaultValue={'Filter Par statut'}
            style={{
              width: 180
            }}
            onChange={handleChangeStatut}
            options={
              chartMode === 'treso'
                ? options.map((item) => ({
                    value: item.value,
                    label: item.label
                  }))
                : optionsCompta.map((item) => ({
                    value: item.value,
                    label: item.label
                  }))
            }
            allowClear={true}
          />
        </div>
      </div>
      <div>
        <ResponsiveContainer width="100%" height={600} className="responsiveContainer">
          <LineChart
            width={500}
            height={600}
            data={listState}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}>
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend layout="horizontal" align="right" verticalAlign="top" content={<CustomLegend />} />
            <Line type="monotone" dataKey="totalTreso" stroke="#FF718B" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="totalCompta" stroke="#962DFF" strokeDasharray="3 4 5 2" />
            <text x={165} y={45} textAnchor="middle" fontWeight="400" fontSize={20} color="#9291A5">
              Affichage graphique
            </text>
            <text x={180} y={75} textAnchor="middle" fontWeight="700" fontSize={25} color="#1E1B39">
              Courbe des soldes
            </text>
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Dashboard;
