import React, { useState } from 'react';

import './Home.scss';
// Import Components
import Header from '../../components/Header/Header';
import SideBar from '../../components/Sidebar/Sidebar';
import Preloader from '../../components/Preloader/Preloader';
import { ToastContainer } from 'react-toastify';

const Home = (props) => {
  const [change, setChange] = useState('');
  const [progress, setProgress] = React.useState(0);
  const [loggedIn, setLoggedIn] = React.useState(false);

  const sendDataElement = (e) => {
    e.prventDefault();
    setChange(true);
  };
  return (
    <>
      {/* {progress !== 100 ? (
        <Preloader progress={progress} setProgress={setProgress} />
      ) : ( */}
      <div className="home">
        <ToastContainer autoClose={2000} />

        <div className="home__sideBar">
          <SideBar />
        </div>
        <div className="home__container">
          <div className="home__container__headerContainer">
            <Header sendDataElement={sendDataElement} />
          </div>
          <div className="home__container__table">{props.children}</div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default Home;
