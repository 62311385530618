import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ajoutBudget from '../../assets/icon/ajoutBudget.svg';
import { colorSoldeTotal, createCategorie, createObjectiveWithCategory, editingAction, getStatutFilter, listSolde } from '../../redux/actions/categories';
import prev from '../../assets/icon/prev.svg';
import engage from '../../assets/icon/engage.svg';
import pointeTreso from '../../assets/icon/pointeTreso.svg';

import pointe from '../../assets/icon/pointe.svg';
import simulation from '../../assets/icon/simulation.svg';
import reel from '../../assets/icon/reel.svg';
import filter from '../../assets/icon/filtreSolde.svg';
import close from '../../assets/icon/Close.svg';

import './Thead.css';
import { filterSoldeByColor } from '../../helpers/filterSoldeByStatus';
import { Button, Dropdown, Input, Menu, Popover } from 'antd';
import { updateObjectif } from '../../redux/actions/objective';
import { useLocation } from 'react-router-dom';
import { getonlyElements } from '../../redux/actions/element';
import { nestCategories } from '../../helpers/helpers';

function Thead({ className, setExpandedCategories, expandedCategories, monthsTotalData, dataSource, setDataSource, title, tableWithElements, ...props }) {
  const dispatch = useDispatch();
  const path = useLocation();

  const [active, setActive] = React.useState(false);
  const [editObjectiveSolde, setEditObjectiveSolde] = React.useState(false);
  const [modeActive, setModeActive] = React.useState(false);
  const colorSolde = useSelector((state) => state.categories.colorSolde);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const monthGroup = useSelector((state) => state.categories.monthFilter);
  const Catgeories = useSelector((state) => state.categories.categories);
  const checkedSynthese = useSelector((state) => state.categories.checkedSynthese);
  const checkedObjectif = useSelector((state) => state.categories.checkedObjectif);
  const SelectedObjective = useSelector((state) => state.categories.SelectedObjective);
  const statutElement = useSelector((state) => state.categories.statutElement);
  const valeurElement = useSelector((state) => state.categories.valeurElement);
  const mode = useSelector((state) => state.categories.mode);

  const userId = JSON.parse(localStorage.getItem('user'))?.id;

  // Showing every month's total to the user
  const getMonthTotal = React.useCallback(
    (month) => (
      <div className="month-total">
        {monthsTotalData[month]
          ? monthsTotalData[month]?.toLocaleString('fi-FI', {
              style: 'currency',
              currency: 'EUR'
            })
          : '0€'}
      </div>
    ),
    [monthsTotalData]
  );

  React.useEffect(() => {
    if (location.pathname == '/') {
      setModeActive(modeActive);
    } else if (location.pathname == '/table-compta') {
      setModeActive(!modeActive);
    } else if (location.pathname === '/categories' && mode === 'treso') {
      setModeActive(modeActive);
    } else if (location.pathname === '/categories' && mode === 'compta') {
      setModeActive(!modeActive);
    }
  }, []);

  const memoizedSpecialCat = React.useMemo(() => nestCategories(Catgeories)?.find((cat) => cat.is_special && cat.parentId === null), [Catgeories]);

  const CATEGORIES = [
    { type: 'normale', label: 'catégorie normale' },
    { type: 'child', label: 'catégorie speciale' }
  ];
  // const mode =
  const handleAddPopover = (e) => {
    const newData = {
      name: '',
      type: 'normale'
    };

    if (dataSource !== undefined) setDataSource([...dataSource, newData]);

    dispatch(editingAction(true, undefined, 'normale'));
  };

  const handleClickActive = () => {
    setActive(!active);
  };

  const handleFilterSolde = async (filterSolde) => {
    const color = filterSoldeByColor(filterSolde);
    dispatch(colorSoldeTotal(color));
    dispatch(getStatutFilter(filterSolde));

    if (location.pathname == '/') {
      await dispatch(
        listSolde(
          'treso',
          dateFilter.startDate,
          dateFilter.endDate,
          monthGroup,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          userId,
          SelectedObjective?.id,
          { filterSolde: filterSolde }
        )
      );
    } else if (location.pathname == '/table-compta') {
      await dispatch(
        listSolde(
          'compta',
          dateFilter.startDate,
          dateFilter.endDate,
          monthGroup,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          userId,
          SelectedObjective?.id,
          { filterSolde: filterSolde }
        )
      );
    } else if (location.pathname === '/categories' && mode === 'treso') {
      if (statutElement) {
        await dispatch(getonlyElements(userId, 'dateTreso', statutElement, '', dateFilter.startDate, dateFilter.endDate, { filterSolde: filterSolde }));
      } else if (valeurElement) {
        dispatch(getonlyElements(userId, 'dateTreso', '', valeurElement, dateFilter.startDate, dateFilter.endDate, { filterSolde: filterSolde }));
      } else {
        await dispatch(getonlyElements(userId, 'dateTreso', '', '', dateFilter.startDate, dateFilter.endDate, { filterSolde: filterSolde }));
      }
    } else if (location.pathname === '/categories' && mode === 'compta') {
      if (statutElement) {
        await dispatch(getonlyElements(userId, 'dateCompta', statutElement, '', dateFilter.startDate, dateFilter.endDate, { filterSolde: filterSolde }));
      } else if (valeurElement) {
        dispatch(getonlyElements(userId, 'dateCompta', '', valeurElement, dateFilter.startDate, dateFilter.endDate, { filterSolde: filterSolde }));
      } else {
        await dispatch(getonlyElements(userId, 'dateCompta', '', '', dateFilter.startDate, dateFilter.endDate, { filterSolde: filterSolde }));
      }
    }
  };

  const editSoldeObjective = (e) => {
    setEditObjectiveSolde(true);
  };

  const saveObjective = async (e) => {
    const dataObjectif = {
      montantObjective: e.target.value,
      userId: userId
    };
    await dispatch(
      updateObjectif(
        SelectedObjective?.id,
        dataObjectif,
        'treso',
        dateFilter.startDate,
        dateFilter.endDate,
        dateFilter.startDateSynthese,
        dateFilter.endDateSynthese
      )
    );
    await setEditObjectiveSolde(false);
  };

  return (
    <thead className={className}>
      {props.children}
      <tr>
        <th className="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last solde">
          <div className="filterSolde">
            <span>SOLDE TOTAL</span>
            <div className={active ? 'header__right__filterActive' : null} onClick={handleClickActive}>
              <img src={filter} />
              {active && !modeActive && (
                <div className="header__right__filterActive__modeFilterActiveTreso">
                  <img src={prev} onClick={() => handleFilterSolde(1)} />
                  <img src={engage} onClick={() => handleFilterSolde(2)} />
                  <img src={pointe} onClick={() => handleFilterSolde(3)} />
                  <img src={close} onClick={() => handleFilterSolde()} />
                </div>
              )}
              {active && modeActive && (
                <div className="header__right__filterActive__modeFilterActiveCompta">
                  <img src={simulation} onClick={() => handleFilterSolde('Simulation')} />
                  <img src={reel} onClick={() => handleFilterSolde('Relle')} />
                  <img src={close} onClick={() => handleFilterSolde()} />
                </div>
              )}
            </div>
          </div>
        </th>
        {checkedObjectif && !tableWithElements && (
          <th className="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left objectif" onDoubleClick={(e) => editSoldeObjective(e)}>
            {!editObjectiveSolde ? (
              getMonthTotal(`${'soldeObjectif'}`)
            ) : (
              <Input defaultValue={monthsTotalData['soldeObjectif']} onPressEnter={saveObjective} onBlur={saveObjective} />
            )}
          </th>
        )}
        {checkedSynthese && !tableWithElements && (
          <th
            className={
              checkedObjectif
                ? 'ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left synthese'
                : 'ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left syntheseNotObjective'
            }>
            {getMonthTotal(`${'soldeSynthese'}`)}
          </th>
        )}
        {!tableWithElements
          ? dateFilter?.monthArray.map((month) => {
              return (
                <>
                  <th style={{ backgroundColor: colorSolde }}>{getMonthTotal(`${'solde' + month}`)}</th>
                </>
              );
            })
          : dateFilter?.monthArray.map((month) => {
              return (
                <>
                  <th style={{ backgroundColor: colorSolde }}>{getMonthTotal(`${month}`)}</th>
                </>
              );
            })}
        <th className="ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-last action"></th>
      </tr>
      <tr>
        <th className="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last cattegory">
          <div className="namecolumn">
            {tableWithElements ? (
              ''
            ) : (
              <>
                <span>Gestion de catégories</span>

                <div>
                  <img src={ajoutBudget} className="ajoutBudget" onClick={handleAddPopover} />
                </div>
              </>
            )}
          </div>
        </th>
        {checkedObjectif && !tableWithElements && (
          <th className="ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left objectif">
            <div className="category-thead-Montant">
              <p>Montant</p>
            </div>
          </th>
        )}
        {checkedSynthese && !tableWithElements && (
          <th
            className={
              checkedObjectif
                ? 'ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left synthese'
                : 'ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left syntheseNotObjective'
            }>
            <div className="category-thead-Montant">
              <p>Montant</p>
            </div>
          </th>
        )}
        {dateFilter?.monthArray.map((month) => {
          return (
            <>
              <th className="ant-table-cell category-thead-container">
                <CategoryThead />
              </th>
            </>
          );
        })}
        <th className="ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-last action"></th>
      </tr>
    </thead>
  );
}

function CategoryThead() {
  return (
    <div className="category-thead">
      <p>Libellé</p>
      <p>Montant</p>
    </div>
  );
}

export default Thead;
