// This file was created to avoid a semantic HTML error
import * as React from 'react';
import { Form, Input } from 'antd';
import { getQueryStringParams } from '../../helpers/helpers';
import ajoutSousBudget from '../../assets/icon/ajoutSousBudget.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  createCategorie,
  createObjectiveWithCategory,
  deleteCategorie,
  editingAction,
  editObjectiveMontant,
  exapndedCategoriesAll,
  listCategorie,
  updateCategorie,
  updateObjectiveWithCategory
} from '../../redux/actions/categories';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';

export default function Td({
  children,
  expanded,
  dataIndex,
  refresh,
  setRefresh,
  dataSource,
  setDataSource,
  expandedCategories,
  setExpandedCategories,
  indexMultiplier,
  dragPrefix,
  ...props
}) {
  const editing = useSelector((state) => state.categories.editing);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const checkedSynthese = useSelector((state) => state.categories.checkedSynthese);
  const checkedObjectif = useSelector((state) => state.categories.checkedObjectif);
  const monthFilter = useSelector((state) => state.categories.monthFilter);
  const editingObjective = useSelector((state) => state.categories.editingObjective);
  const list = useSelector((state) => state.categories.categories);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  const SelectedObjective = useSelector((state) => state.categories.SelectedObjective);
  // console.log(SelectedObjective);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // const location.pathname == '/table-compta'
  const inputRef = React.useRef(null);
  React.useEffect(() => {
    inputRef?.current?.focus();
  });

  const toggleEdit = (e) => {
    dispatch(editingAction(true, e.target.id));
  };

  const save = async (e) => {
    try {
      if (dataIndex === 'objectif') {
        const dataObjectiveCategoy = {
          categorie_id: editingObjective?.id,
          objective_id: SelectedObjective?.id,
          montant: e.target.value,
          userId: userId
        };
        await dispatch(
          updateObjectiveWithCategory(
            editingObjective?.id,
            dataObjectiveCategoy,
            'treso',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese
          )
        ).then(() => {
          dispatch(
            listCategorie(
              pathname === '/table-compta' ? 'compta' : 'treso',
              dateFilter.startDate,
              dateFilter.endDate,
              monthFilter,
              dateFilter.startDateSynthese,
              dateFilter.endDateSynthese,
              userId,
              SelectedObjective?.id
            )
          );
        });
        await dispatch(editObjectiveMontant(false));
      } else {
        if (props.record.id === undefined) {
          if (dataSource?.length == 0) {
            await dispatch(
              createCategorie({
                name: e.target.value,
                levelId: 1,
                userId: userId,
                index: 0,
                is_special: editing?.type === 'special',
                type: editing?.type === 'special' ? (pathname === '/table-compta' ? 'compta' : 'treso') : null
              })
            );
            await dispatch(
              listCategorie(
                pathname === '/table-compta' ? 'compta' : 'treso',
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese,
                userId,
                SelectedObjective?.id
              )
            );
          } else if (dataSource?.length > 0) {
            const lastElement = dataSource[dataSource.length - 1];
            if (Object.keys(lastElement).length === 2 && !e.target.value) {
              dispatch(editingAction(false));
              setDataSource((prev) => {
                prev?.pop();
                return prev;
              });
              return;
            }

            await dispatch(
              createCategorie({
                name: e.target.value,
                levelId: 1,
                userId: userId,
                index: dataSource?.length - 1,
                is_special: editing?.type === 'special',
                type: editing?.type === 'special' ? (pathname === '/table-compta' ? 'compta' : 'treso') : null
              })
            );
            await dispatch(
              listCategorie(
                pathname === '/table-compta' ? 'compta' : 'treso',
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese,
                userId,
                SelectedObjective?.id
              )
            );
          }
        } else {
          if (e.target.value.length > 0) {
            dispatch(
              updateCategorie(
                props.record.id,
                { name: e.target.value, userId: userId },
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese,
                pathname === '/table-compta' ? 'compta' : 'treso'
              )
            ).then(() => {
              dispatch(
                listCategorie(
                  pathname === '/table-compta' ? 'compta' : 'treso',
                  dateFilter.startDate,
                  dateFilter.endDate,
                  monthFilter,
                  dateFilter.startDateSynthese,
                  dateFilter.endDateSynthese,
                  userId,
                  SelectedObjective?.id
                )
              );
            });
          } else {
            dispatch(deleteCategorie(props.record.id)).then(() => {
              dispatch(
                listCategorie(
                  pathname === '/table-compta' ? 'compta' : 'treso',
                  dateFilter.startDate,
                  dateFilter.endDate,
                  monthFilter,
                  dateFilter.startDateSynthese,
                  dateFilter.endDateSynthese,
                  userId,
                  SelectedObjective?.id
                )
              );
            });
          }
        }
      }
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleAddSUb = async () => {
    setExpandedCategories(() => {
      const index = expandedCategories.findIndex((ec) => ec === props.record.key);
      if (index > -1 && !expanded) return expandedCategories.filter((ec) => ec !== props.record.key);
      else return [...expandedCategories, props.record.key];
    });

    let newChildIndex = props.record?.children === undefined ? 0 : props.record?.children?.length;

    const newChild = {
      name: '',
      parentId: props.record.key,
      levelId: props.record.levelId + 1,
      ...(props?.record?.is_special && { is_special: true, type: props.record.type }),
      userId: userId,
      index: newChildIndex
    };
    setDataSource([...dataSource]);
    dispatch(editingAction(true, undefined, 'child'));
    const isEmpty = props.record?.children?.some((el) => el?.name === '');
    if (props.record?.children !== undefined && isEmpty) return;
    dispatch(createCategorie(newChild));
  };

  const renderDraggableFirst = (provided, snapshot) => {
    // Rendering switch category
    return <div {...provided.droppableProps} ref={provided.innerRef} className={`catswitchFirst ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}></div>;
  };

  const renderDraggable = (provided, snapshot) => {
    // Rendering switch category
    return <div {...provided.droppableProps} ref={provided.innerRef} className={`catswitch ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}></div>;
  };
  // (dataIndex === 'name' && props.record?.id && editing) ||
  // (props.record?.name?.length == 0 && dataIndex === 'name' && editing) ||

  return (
    <td
      className={
        dataIndex && dataIndex === 'name'
          ? 'table-td ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last cattegory'
          : dataIndex && dataIndex === 'delete'
          ? 'table-td ant-table-cell ant-table-cell-fix-right ant-table-cell-fix-right-last action'
          : dataIndex && dataIndex === 'synthese' && checkedSynthese && checkedObjectif
          ? 'table-td ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last synthese'
          : dataIndex && dataIndex === 'objectif' && checkedObjectif
          ? 'table-td ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last objectif'
          : dataIndex && dataIndex === 'synthese' && !checkedObjectif && checkedSynthese
          ? 'table-td ant-table-cell ant-table-cell-fix-left ant-table-cell-fix-left-last syntheseNotObjective'
          : 'table-td '
      }>
      {dataIndex === 'name' && props.record?.name?.length == 0 && (
        <Droppable
          droppableId={
            'category=' +
            props.record.id +
            '&month=' +
            'CATEGORY' +
            '&levelId=' +
            props.record.levelId +
            '&index=' +
            props.record.index +
            '&parentId=' +
            props.record.parentId +
            '&switch=true' +
            '&first=true' +
            '&is_special=' +
            props.record?.is_special
          }>
          {(provided, snapshot) => <>{renderDraggableFirst(provided, snapshot)}</>}
        </Droppable>
      )}
      {dataIndex === 'name' && props.record?.name?.length == 0 && editing?.type !== undefined ? (
        <>
          {editing?.type === 'normale' && (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} placeholder="créer une catégorie normale." defaultValue={editingObjective?.value} />
          )}
          {editing?.type === 'child' && (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} placeholder="créer une sous-catégorie." defaultValue={editingObjective?.value} />
          )}
        </>
      ) : dataIndex === 'objectif' && editingObjective?.status && editingObjective?.id === props.record?.id ? (
        <Input ref={inputRef} onPressEnter={save} onBlur={save} placeholder="créer une sous-catégorie." defaultValue={editingObjective?.value} />
      ) : props.record?.name?.length == 0 && editing?.type === undefined ? (
        <></>
      ) : (
        <>
          <div
            style={
              dataIndex && dataIndex === 'name'
                ? {
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '10px',
                    ...(props?.record?.is_special && { background: '#e2e2e2' })
                  }
                : {}
            }
            onDoubleClick={(e) => toggleEdit(e)}>
            {children?.map((c) => {
              return c ? (
                <>
                  <div key={getQueryStringParams(c?.props.dragPrefix).category || new Date().toString()}>{React.cloneElement(c, { expanded })}</div>
                  {/* {dataIndex === 'name' && props.record?.name?.length == 0 && editing?.type !== undefined && editing?.type === 'special' && <div>special</div>} */}
                </>
              ) : null;
            })}
            {dataIndex === 'name' && props.record.levelId !== 5 && (
              <div className="addSubCat">
                <img src={ajoutSousBudget} className="ajoutBSousudget" onClick={() => handleAddSUb()} />
              </div>
            )}
          </div>
        </>
      )}

      {dataIndex && dataIndex === 'name' && props.record.index !== 0 && (
        <Droppable
          droppableId={
            'category=' +
            props.record.id +
            '&month=' +
            'CATEGORY' +
            '&levelId=' +
            props.record.levelId +
            '&index=' +
            props.record.index +
            '&parentId=' +
            props.record.parentId +
            '&switch=true' +
            '&is_special=' +
            props.record?.is_special
          }>
          {(provided, snapshot) => <>{renderDraggable(provided, snapshot)}</>}
        </Droppable>
      )}
    </td>
  );
}
