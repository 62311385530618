import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { chekDate } from '../../helpers/checkDate';
import { getQueryStringParams, MONTHS } from '../../helpers/helpers';
import { listSolde, updateCategorie, updateCategorieMultiple } from '../../redux/actions/categories';
import { updateElement } from '../../redux/actions/element';
import { debounce, remove } from 'lodash';

const DragAndDropContext = (props) => {
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const monthFilter = useSelector((state) => state.categories.monthFilter);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  const getStatutFilter = useSelector((state) => state.categories.getStatutFilter);
  const SelectedObjective = useSelector((state) => state.categories.SelectedObjective);

  const dispatch = useDispatch();

  const { children, apiData, setApiData, title } = props;

  const onDragEnd = async (result) => {
    console.log({ result });
    // In all cases first we need to get the dragged item ( it may be category or element )
    // getQueryStringParams returns ->
    // category which is category ID
    // month which is month's name like January
    // index in case of element -> index of the element inside the elements array under category object
    const {
      category: draggedCategory,
      month: draggedMonth,
      index: draggedIndex,
      type: draggedType,
      statut: draggedStatut,
      levelId: draggedLevel,
      is_special: is_special
    } = getQueryStringParams(result.draggableId);

    console.log({ is_special });
    // There are serval conditions we need to check
    // before exiting the onDragEnd method
    // 1- if the result is null and the dragged item is an element
    // 2- or result not null and the draggable item was droped in the same location
    if ((!result.destination && draggedMonth !== 'CATEGORY') || (result.destination && result.draggableId === result.destination.droppableId))
      // in case there's no destination or dragged element dropped on it's same position
      return;

    // Same for the one on top, except here we are getting
    // info about the dropped item
    // it may be a category or an element
    const {
      category: droppedOnCategory,
      month: droppedOnMonth,
      levelId: droppedOnLevel,
      first: droppedFirst,
      switch: droppedSwitch,
      is_special: droppedIsSpecial
    } = getQueryStringParams(result.destination?.droppableId || '');

    // We need to check if an element was dropped on a category or the inverse
    // If so, we need to trigger an exception ( I've used a javascript Alert )
    console.log({ droppedIsSpecial });
    if (is_special !== droppedIsSpecial) {
      return toast.info("Attention: cette action n'est pas applicable.  ) ");
    }
    if (
      (draggedMonth === 'CATEGORY' && MONTHS.includes(droppedOnMonth?.split(' ')[0] || '')) ||
      (droppedOnMonth === 'CATEGORY' && MONTHS.includes(draggedMonth?.split(' ')[0] || ''))
    ) {
      alert("Problem: You're trying to drag and drop element on a category or the inverse.");
      return;

      // We made sure that everything is ready to visualize
      // the drag and drop for the user
    } else {
      // MONTHS array contains all the months
      // We just need to know if drag & drop was done
      // on elements or a category
      // This case means that the dnd was done on elements
      if (MONTHS.includes(draggedMonth?.split(' ')[0] || '') || MONTHS.includes(droppedOnMonth?.split(' ')[0] || '')) {
        // in case each category id is string otherwise use == instead of ===
        const [draggedElement] = apiData.find((c) => c.id == draggedCategory).elements[draggedMonth || ''].splice([draggedIndex || 0], 1);

        const _apiData = Array.from(apiData);

        if (title === 'Trésorerie') {
          console.log('title:', title)
          if (draggedStatut == 3) {
            if (droppedOnCategory == draggedCategory) {
              toast.info(
                "Attention: cette action n'est pas applicable pour les éléments de type Pointé, déplacement vertical seulement (d'une catégorie à une autre ) "
              );
            }
            const updateData = {
              categorieId: droppedOnCategory,
              userId: userId
            };
            console.log('updateData:', updateData)
            await dispatch(
              updateElement(
                draggedElement?.id,
                updateData,
                title,
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese,
                {
                  filterSolde: getStatutFilter ? getStatutFilter : ''
                },
                SelectedObjective?.id
              )
            );
            /*   await dispatch(
              listSolde('treso', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
            ); */
          } else if (monthFilter !== undefined) {
            console.log('monthFilter:', monthFilter)
            const updateData = {
              dateTreso: chekDate(droppedOnMonth.split('-')[0]),
              categorieId: droppedOnCategory,
              userId: userId
            };
            await dispatch(
              updateElement(
                draggedElement?.id,
                updateData,
                title,
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese,
                {
                  filterSolde: getStatutFilter ? getStatutFilter : ''
                },
                SelectedObjective?.id
              )
            );
            /*  await dispatch(
              listSolde('treso', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
            ); */
          } else {
            const updateData = {
              
              dateTreso: chekDate(droppedOnMonth),
              categorieId: droppedOnCategory,
              userId: userId
            };
            console.log('updateData:', updateData)
            await dispatch(
              updateElement(
                draggedElement?.id,
                updateData,
                title,
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese,
                {
                  filterSolde: getStatutFilter ? getStatutFilter : ''
                },
                SelectedObjective?.id
              )
            );
            /*   await dispatch(
              listSolde('treso', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
            ); */
          }
        } else if (title === 'Comptabilité') {
          console.log('title:', title)
          if (draggedType === 'Relle') {
            toast.info(
              "Attention: cette action n'est pas applicable pour les éléments de type réels, déplacement vertical seulement (d'une catégorie à une autre ) "
            );
            const updateData = {
              categorieId: droppedOnCategory,
              userId: userId
            };
            console.log('updateData:', updateData)

            await dispatch(
              updateElement(
                draggedElement?.id,
                updateData,
                title,
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese,
                {
                  filterSolde: getStatutFilter ? getStatutFilter : ''
                },
                SelectedObjective?.id
              )
            );
            /*  await dispatch(
              listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
            ); */
          } else if (monthFilter !== undefined) {
            console.log('monthFilter:', monthFilter)
            const updateData = {
              dateCompta: chekDate(droppedOnMonth.split('-')[0]),
              dateTreso: chekDate(droppedOnMonth.split('-')[0]),
              categorieId: droppedOnCategory,
              userId: userId
            };
            await dispatch(
              updateElement(
                draggedElement?.id,
                updateData,
                title,
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese,
                {
                  filterSolde: getStatutFilter ? getStatutFilter : ''
                },
                SelectedObjective?.id
              )
            );
            /*   await dispatch(
              listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
            ); */
          } else {
            const updateData = {
              dateCompta: chekDate(droppedOnMonth),
              dateTreso: chekDate(droppedOnMonth),
              categorieId: droppedOnCategory,
              userId: userId
            };
            console.log('updateData:', updateData)

            await dispatch(
              updateElement(
                draggedElement?.id,
                updateData,
                title,
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese,
                {
                  filterSolde: getStatutFilter ? getStatutFilter : ''
                },
                SelectedObjective?.id
              )
            );
            /* await dispatch(
              listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
            ); */
          }
        }
      } else {
        if (!droppedSwitch) {
          const recursiveCategory = (draggedCategory, droppedCategory) => {
            if (droppedCategory.parentId == null) {
              return false;
            } else if (draggedCategory.id == droppedCategory.parentId) {
              return true;
            } else {
              let filsDropped = _apiData.find((c) => c.id == droppedCategory.parentId);
              return recursiveCategory(draggedCategory, filsDropped);
            }
          };

          // This case means that the dnd was done on categories
          const _apiData = Array.from(apiData);
          if (!result.destination) {
            // in this case if the result.destination is null
            // we need to set the parentId of the category to null
            // so it goes to the top level.
            let arrayDataSansDestination = _apiData.filter((c) => c.parentId == null);

            var draggedCat = _apiData.find((c) => c.id == draggedCategory);
            let arrayResult = [];
            _apiData
              .filter((cat) => cat.parentId == draggedCat.parentId)
              .forEach((el) => {
                if (el.index > draggedCat.index) {
                  let obj = { ...el };
                  obj.index -= 1;
                  arrayResult.push(obj);
                }
              });

            draggedCat.parentId = null;
            draggedCat.index = arrayDataSansDestination?.length;
            arrayResult.push(draggedCat);

            if (monthFilter !== undefined) {
              let dataByIndexCategoy = {
                categories: arrayResult
              };
              dispatch(
                updateCategorieMultiple(
                  userId,
                  dataByIndexCategoy,
                  dateFilter.startDate,
                  dateFilter.endDate,
                  monthFilter,
                  dateFilter.startDateSynthese,
                  dateFilter.endDateSynthese
                )
              );
            } else {
              let dataByIndexCategoy = {
                categories: arrayResult
              };
              dispatch(
                updateCategorieMultiple(
                  userId,
                  dataByIndexCategoy,
                  dateFilter.startDate,
                  dateFilter.endDate,
                  monthFilter,
                  dateFilter.startDateSynthese,
                  dateFilter.endDateSynthese
                )
              );
            }
          } else {
            var draggedCatWithDestination = _apiData.find((c) => c.id == draggedCategory);
            var droppedCatWithDestination = _apiData.find((c) => c.id == droppedOnCategory);

            const draggedcategoryParent = draggedCatWithDestination.parentId;
            const draggedId = draggedCatWithDestination.id;
            const droppedOncategoryParent = droppedCatWithDestination.parentId;
            const rejected = recursiveCategory(draggedCatWithDestination, droppedCatWithDestination);
            let arrayResultWithDestination = [];
            _apiData
              .filter((cat) => cat.parentId == draggedCatWithDestination.parentId)
              .forEach((el) => {
                if (el.index > draggedCatWithDestination.index) {
                  let obj = { ...el };
                  obj.index -= 1;
                  arrayResultWithDestination.push(obj);
                }
              });

            if (rejected) {
              toast.error("Attention: Ce n'est pas possible de placer une catégorie parente dans son fils");
            }

            // otherwise we just need to set the category parentId
            else {
              let arrayData = _apiData.filter((c) => c.parentId == JSON.parse(droppedOnCategory));

              draggedCatWithDestination.parentId = JSON.parse(droppedOnCategory);
              draggedCatWithDestination.index = arrayData?.length;
              arrayResultWithDestination.push(draggedCatWithDestination);

              if (monthFilter !== undefined) {
                let dataByIndexCategoy = {
                  categories: arrayResultWithDestination
                };
                dispatch(
                  updateCategorieMultiple(
                    userId,
                    dataByIndexCategoy,
                    dateFilter.startDate,
                    dateFilter.endDate,
                    monthFilter,
                    dateFilter.startDateSynthese,
                    dateFilter.endDateSynthese
                  )
                );
              } else {
                let dataByIndexCategoy = {
                  categories: arrayResultWithDestination
                };
                dispatch(
                  updateCategorieMultiple(
                    userId,
                    dataByIndexCategoy,
                    dateFilter.startDate,
                    dateFilter.endDate,
                    monthFilter,
                    dateFilter.startDateSynthese,
                    dateFilter.endDateSynthese
                  )
                );
              }
            }

            // setExpandedCategories(expandedCategories.filter((ec: any) => ec !== draggedCategory));
            // setExpandedCategories([...expandedCategories, droppedOnCategory]);
          }
          // Setting the new data so the user will get the expected result.
          // In this case you may call an api request to save the new data
          // in the database and REFETCH to get the expected result in
          // the first render.
          setApiData(_apiData);
        } else {
          // switch category
          const _apiData = Array.from(apiData);

          var draggedCatWithDestinationSwitch = _apiData.find((c) => c.id == draggedCategory);
          var droppedCatWithDestinationSwitch = _apiData.find((c) => c.id == droppedOnCategory);

          const draggedcategoryParent = draggedCatWithDestinationSwitch.parentId;
          const draggedId = draggedCatWithDestinationSwitch.id;
          const droppedOncategoryParent = droppedCatWithDestinationSwitch.parentId;
          console.log('droppedCatWithDestinationSwitch.index', droppedCatWithDestinationSwitch.index);

          /*   if ((draggedcategoryParent === null && droppedOncategoryParent === draggedId) || droppedOncategoryParent === draggedId) {
            toast.error("Attention: Ce n'est pas possible de placer une catégorie parente dans son fils");
          }
 */
          if (draggedcategoryParent == droppedOncategoryParent) {
            if (draggedCatWithDestinationSwitch.index < droppedCatWithDestinationSwitch.index) {
              const dataFilterSwitch = _apiData.filter((c) => c.parentId == draggedcategoryParent);
              let arraywithswitch = [];
              // organized category
              dataFilterSwitch.forEach((el) => {
                if (el.index > draggedCatWithDestinationSwitch.index && el.index <= droppedCatWithDestinationSwitch.index) {
                  let obj = { ...el };
                  obj.index = el.index - 1;
                  arraywithswitch.push(obj);
                }
              });

              let draggednew = { ...draggedCatWithDestinationSwitch };
              draggednew.index = droppedCatWithDestinationSwitch.index;
              arraywithswitch.push(draggednew);

              let dataswitchCategoy = {
                categories: arraywithswitch
              };

              dispatch(
                updateCategorieMultiple(
                  userId,
                  dataswitchCategoy,
                  dateFilter.startDate,
                  dateFilter.endDate,
                  monthFilter,
                  dateFilter.startDateSynthese,
                  dateFilter.endDateSynthese
                )
              );
            } else if (draggedCatWithDestinationSwitch.index > droppedCatWithDestinationSwitch.index) {
              const dataFilterSwitch = _apiData.filter((c) => c.parentId == draggedcategoryParent);
              let arraywithswitch = [];
              // organized category
              dataFilterSwitch.forEach((el) => {
                if (el.index < draggedCatWithDestinationSwitch.index && el.index > droppedCatWithDestinationSwitch.index) {
                  let obj = { ...el };
                  obj.index = el.index + 1;
                  arraywithswitch.push(obj);
                } else if (el.index == 0 && droppedFirst) {
                  let obj = { ...el };
                  obj.index = el.index + 1;
                  arraywithswitch.push(obj);
                }
              });

              let draggednew = { ...draggedCatWithDestinationSwitch };
              if (droppedFirst) {
                draggednew.index = droppedCatWithDestinationSwitch.index;
              } else {
                draggednew.index = droppedCatWithDestinationSwitch.index + 1;
              }
              arraywithswitch.push(draggednew);

              let dataswitchCategoy = {
                categories: arraywithswitch
              };

              dispatch(
                updateCategorieMultiple(
                  userId,
                  dataswitchCategoy,
                  dateFilter.startDate,
                  dateFilter.endDate,
                  monthFilter,
                  dateFilter.startDateSynthese,
                  dateFilter.endDateSynthese
                )
              );
            }
          } else {
            const dataFilterSwitch = _apiData.filter((c) => c.parentId == draggedcategoryParent);

            const dataFilterSwitchParent = _apiData.filter((c) => c.parentId == droppedOncategoryParent);

            let arraywithswitch = [];

            dataFilterSwitchParent.forEach((el, index) => {
              let objDropped = { ...el };
              if (droppedFirst) {
                objDropped.index += 1;
              } else if (el.index >= droppedCatWithDestinationSwitch.index && el.index != 0) {
                objDropped.index += 1;
              }
              arraywithswitch.push(objDropped);
            });

            let draggednew = { ...draggedCatWithDestinationSwitch };
            draggednew.parentId = droppedOncategoryParent;
            draggednew.index =
              droppedCatWithDestinationSwitch.index === 0 && droppedFirst ? droppedCatWithDestinationSwitch.index : droppedCatWithDestinationSwitch.index + 1;
            arraywithswitch.push(draggednew);

            // organized category
            dataFilterSwitch.forEach((el, index) => {
              let obj = { ...el };
              if (obj.id != draggedId && obj.index > draggedCatWithDestinationSwitch.index) {
                obj.index = el.index - 1;
                arraywithswitch.push(obj);
              }
            });

            let dataswitchCategoy = {
              categories: arraywithswitch
            };

            dispatch(
              updateCategorieMultiple(
                userId,
                dataswitchCategoy,
                dateFilter.startDate,
                dateFilter.endDate,
                monthFilter,
                dateFilter.startDateSynthese,
                dateFilter.endDateSynthese
              )
            );
            // toast.error("Attention: Ce n'est pas possible de placer une catégorie parente dans son fils");
          }
        }
      }
    }

    // end of onDragEnd
    return;
  };
  const debouncedHandleDragAndDrop = debounce(onDragEnd, 50);

  return (
    <DragDropContext onDragEnd={debouncedHandleDragAndDrop}>
      <tbody>{children}</tbody>
    </DragDropContext>
  );
};

export default DragAndDropContext;
