import * as React from 'react';
import { Button, Popconfirm, Spin, Switch, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Thead from '../Thead/Thead';
import Cell from '../CustomCell/CustomCell';
import Td from '../Td/Td';
import DragAndDropContext from '../DragAndDropContext/DragAndDropContext';
import { getDefaultColumns, MONTHS, nestCategories } from '../../helpers/helpers';
import 'antd/dist/antd.css';
import './CustomeTable.css';
import {
  ObjectiveSelected,
  checkedSynthse,
  deleteCategorie,
  deleteCategorieOne,
  exapndedCategoriesAll,
  filterDateAction,
  listCategorie,
  listSolde,
  loadingToggleAction,
  monthFilter,
  scrollToPoistionTbale
} from '../../redux/actions/categories';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import Tr from '../Tr/Tr';
import { addNewObjectif } from '../../redux/actions/objective';
import { modeElement } from '../../redux/actions/element';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomTable = ({ title }) => {
  const lists = useSelector((state) => state.categories.categories);
  const listsSolde = useSelector((state) => state.categories.soldeAll);
  const loading = useSelector((state) => state.categories.showLoading);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const checkedSynthese = useSelector((state) => state.categories.checkedSynthese);
  const checkedObjectif = useSelector((state) => state.categories.checkedObjectif);
  const monthFilterGroup = useSelector((state) => state.categories.monthFilter);
  const modeTable = useSelector((state) => state.categories.mode);
  const SelectedObjective = useSelector((state) => state.categories.SelectedObjective);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  const getStatutFilter = useSelector((state) => state.categories.getStatutFilter);
  const expandedCategorieAll = useSelector((state) => state.categories.expandedCategorieAll);
  const scrollToTable = useSelector((state) => state.categories.scrollToTable);

  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const { pathname } = useLocation();

  const [expandedCategories, setExpandedCategories] = React.useState([]);

  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  const [refresh, setRefresh] = React.useState(false);

  // Data supposed from an API request ( its not nested )
  const [apiData, setApiData] = React.useState([]);

  // dataSource after nesting and adding some other attributes
  // ( check the useEffect ) inside App.tsx
  const [dataSource, setDataSource] = React.useState([]);

  const tableRef = React.useRef(null);
  const [scrollPosition, setScrollPosition] = React.useState({ x: 0, y: 0 });

  // Each month's total ( supposed to be from an API request )
  const monthsTotalData = React.useMemo(() => listsSolde || [], [listsSolde]);

  // Every cell render method
  // it includes category rendering and elements rendering -> check ./components/Cell.tsx
  const categoryExpanded = React.useCallback(
    (recordId) => {
      return expandedCategories.includes(recordId);
    },
    [expandedCategories]
  );

  // Function Delete Catgeory
  const handleDeleteCategory = async (key) => {
    const deltetdCat = lists?.find((c) => c.id == key);

    let arrayResulUpdateCat = [];
    lists
      .filter((cat) => cat.parentId == deltetdCat.parentId)
      .forEach((el) => {
        if (el.index > deltetdCat.index) {
          let obj = { ...el };
          obj.index -= 1;
          arrayResulUpdateCat.push(obj);
        }
      });
    let data = {
      categoreisDeletedId: key,
      categories: arrayResulUpdateCat
    };
    await dispatch(
      deleteCategorieOne(
        userId,
        data,
        '',
        dateFilter?.startDate,
        dateFilter?.endDate,
        dateFilter.startDateSynthese,
        dateFilter.endDateSynthese,
        pathname === '/table-compta' ? 'compta' : 'treso'
      )
    );
    if (title === 'Trésorerie' && modeTable === 'treso') {
      await dispatch(
        listCategorie(
          'treso',
          dateFilter.startDate,
          dateFilter.endDate,
          monthFilterGroup,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          userId,
          SelectedObjective?.id
        )
      );
      await dispatch(
        listSolde(
          'treso',
          dateFilter.startDate,
          dateFilter.endDate,
          monthFilterGroup,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          userId,
          SelectedObjective?.id
        )
      );
    } else if (title === 'Comptabilité' && modeTable === 'compta') {
      await listCategorie(
        'compta',
        dateFilter.startDate,
        dateFilter.endDate,
        monthFilterGroup,
        dateFilter.startDateSynthese,
        dateFilter.endDateSynthese,
        userId,
        SelectedObjective?.id
      );
      await dispatch(
        listSolde(
          'compta',
          dateFilter.startDate,
          dateFilter.endDate,
          monthFilterGroup,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          userId,
          SelectedObjective?.id
        )
      );
    }
  };

  const renderCell = React.useCallback(
    (props) => {
      return <Cell {...props} expandedCategories={expandedCategories} setExpandedCategories={setExpandedCategories} />;
    },
    [expandedCategories, setExpandedCategories]
  );

  const addObjective = async (e) => {
    const dataObjectif = {
      nameObjective: e.target.value,
      userId: userId
    };
    const objectedSelected = await dispatch(
      addNewObjectif(dataObjectif, 'treso', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese)
    );
    await dispatch(ObjectiveSelected(objectedSelected[objectedSelected.length - 1]));
  };

  const renderAction = React.useCallback(
    (props) => {
      if (props.is_special && props.parentId === null) {
        return null;
      }
      return (
        // Rendering actions
        <Popconfirm
          placement="bottomRight"
          title="Êtes-vous sûr？"
          icon={
            <QuestionCircleOutlined
              style={{
                color: 'red'
              }}
            />
          }
          onConfirm={() => handleDeleteCategory(props.id)}>
          <div className="btdelteCategory">
            <DeleteOutlined />
          </div>
        </Popconfirm>
      );
    },
    [dateFilter, lists]
  );

  const handleChangeDate = (value, dateString) => {
    const startDate = moment(dateString[0], 'YYYY-MM');
    const endDate = moment(dateString[1], 'YYYY-MM');
    dispatch(filterDateAction(dateFilter.startDate, dateFilter.endDate, dateFilter.monthArray, startDate.format('YYYY-MM'), endDate.format('YYYY-MM')));
  };

  // Getting table default columns
  // check ./helpers.ts ->  getDefaultColumns method for more info
  const defaultColumns = React.useMemo(
    () => getDefaultColumns(renderCell, title, renderAction, dateFilter, checkedSynthese, handleChangeDate, checkedObjectif, SelectedObjective, addObjective),
    [renderCell, title, renderAction, dateFilter, checkedSynthese, checkedObjectif, SelectedObjective, addObjective]
  );

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  React.useEffect(async () => {
    if (dateFilter) {
      if (location.pathname == '/') {
        await dispatch(
          listCategorie(
            'treso',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilterGroup,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese,
            userId,
            SelectedObjective?.id
          )
        );
        await dispatch(
          listSolde(
            'treso',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilterGroup,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese,
            userId,
            SelectedObjective?.id,
            {
              filterSolde: getStatutFilter ? getStatutFilter : ''
            }
          )
        );
      } else if (location.pathname == '/table-compta') {
        await dispatch(
          listCategorie(
            'compta',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilterGroup,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese,
            userId,
            SelectedObjective?.id
          )
        );
        await dispatch(
          listSolde(
            'compta',
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilterGroup,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese,
            userId,
            SelectedObjective?.id,
            {
              filterSolde: getStatutFilter ? getStatutFilter : ''
            }
          )
        );
      }
    }
  }, [dispatch, dateFilter, location.pathname]);

  React.useEffect(() => {
    if (modeTable === 'treso') {
      naviagte('/');
    } else if (modeTable === 'compta') {
      naviagte('/table-compta');
    }
  }, [modeTable]);

  React.useEffect(() => {
    setApiData(lists);
  }, [lists]);

  React.useEffect(() => {
    if (expandedCategorieAll?.length > 0) {
      setExpandedCategories(expandedCategorieAll);
    }
  }, [expandedCategorieAll]);

  // 1) First we need to add a key and name attribute for each category
  // 2) nesting categories so we can give it to ant table to give us nested children
  React.useEffect(() => {
    const result = apiData?.map((c) => ({
      ...c,
      key: c.id,
      name: c.name
    }));
    setDataSource(nestCategories(result));
  }, [apiData]);

  // Preparing final columns and sending required props for each cell.
  const columns = React.useMemo(
    () =>
      defaultColumns.map((col) => {
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            className: col.className,
            // coords: record.name?.toLowerCase().replace(/ /g, '-') + "-" + col.dataIndex + "-dataindex=" + col.dataIndex, // this will be the draggable ID
            expanded: categoryExpanded(record.id)
          })
        };
      }),
    [categoryExpanded, defaultColumns]
  );

  const rowExpanded = React.useMemo(() => (expanded, record) => expanded || expandedCategories.includes(record.id), [expandedCategories]);

  const onExpand = (_, { key }) => {
    if (_) {
      setExpandedCategories([...expandedCategories, key]);
    } else {
      setExpandedCategories(Array.from(expandedCategories).filter((el) => el != key));
    }
  };

  return (
    <>
      <Table
        style={{
          width:
            checkedSynthese && checkedObjectif
              ? 790 + dateFilter?.monthArray.length * 250
              : checkedSynthese || checkedObjectif
              ? 540 + dateFilter?.monthArray.length * 250
              : 290 + dateFilter?.monthArray.length * 250
        }}
        ref={tableRef}
        className="custom-table"
        components={{
          header: {
            wrapper: (props) => (
              <Thead
                {...props}
                monthsTotalData={monthsTotalData}
                dataSource={dataSource}
                setExpandedCategories={setExpandedCategories}
                expandedCategories={expandedCategories}
                setDataSource={setDataSource}
                title={title}
              />
            )
          },
          body: {
            wrapper: (props) => (
              <DragAndDropContext
                {...props}
                apiData={apiData}
                setApiData={setApiData}
                expandedCategories={expandedCategories}
                setRefresh={setRefresh}
                refresh={refresh}
                setExpandedCategories={setExpandedCategories}
                title={title}
              />
            ),
            cell: (props) => (
              <Td
                {...props}
                setRefresh={setRefresh}
                refresh={refresh}
                dataSource={dataSource}
                setDataSource={setDataSource}
                expandedCategories={expandedCategories}
                setExpandedCategories={setExpandedCategories}
              />
            )
          }
        }}
        expandable={{
          onExpand: onExpand,
          expandedRowKeys: expandedCategories,
          expandIcon: ({ expanded, onExpand, record }) => {
            return (
              <>
                {
                  <button
                    onClick={(e) => {
                      setExpandedCategories(() => {
                        const index = expandedCategories.findIndex((ec) => ec === record.id);
                        if (index > -1) return expandedCategories.filter((ec) => ec !== record.id);
                        else return [...expandedCategories, record.id];
                      });
                      /*      const index = expandedCategories.findIndex((ec) => ec === record.id);
                      if (index > -1) dispatch(exapndedCategoriesAll(expandedCategories.filter((ec) => ec !== record.id)));
                      else dispatch(exapndedCategoriesAll([...expandedCategories, record.id])); */
                      onExpand(record, e);
                    }}
                    className={'btExpand'}>
                    {record.levelId <= 5 &&
                      (rowExpanded(expanded, record) ? (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.46997 13.4599L12 9.93994L15.53 13.4599" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      ) : (
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M8.46997 10.6399L12 14.1599L15.53 10.6399" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      ))}
                  </button>
                }
              </>
            );
          }
        }}
        bordered
        virtualized
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        size="small"
        scroll={{
          y: windowSize.innerWidth < 1900 ? 'calc(100vh - 33vh)' : 'calc(100vh - 25vh)',
          thumb: { width: '50px', background: '#33cc00' }
        }}
      />
      {/* {loading && <Spin />} */}
    </>
  );
};
export default CustomTable;
