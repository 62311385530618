import {
  CREATE_CATEGORIE_SUCCESS,
  CREATE_CATEGORIE_FAIL,
  LIST_CATEGORIE_SUCCESS,
  LIST_CATEGORIE_FAIL,
  LIST_CATEGORIE_All,
  LIST_CATEGORIE_SUCCESS_FINAL,
  LIST_ELEMENTS_SUCCESS,
  GET_CATEGORIE_SUCCESS,
  GET_CATEGORIE_FAIL,
  UPDATE_CATEGORIE_SUCCESS,
  EDITING_ACTION,
  LOADING_TOGGLE_ACTION,
  COLOR_SOLDE_ACTION,
  DELETE_CATEGORIE_SUCCESS,
  FILTER_DATE_ACTION,
  LIST_CATEGORIE_COMPACT,
  LIST_CATEGORIE_COMPACT_STATUT,
  MONTH_FILTER_SUCCESS,
  CHECKED_SYNTHESE_SUCCESS,
  CHECKED_OBJECTIF_SUCCESS,
  SELECTED_OBJECTIF_SUCCESS,
  EDITING_OBJECTIVE_ACTION,
  CREATE_OBJECTIVE_CATEGORIE_SUCCESS,
  CREATE_OBJECTIVE_CATEGORIE_FAIL,
  UPDATE_OBJECTIVE_CATEGORIE_SUCCESS,
  UPDATE_OBJECTIVE_CATEGORIE_FAIL,
  SELECTED_MODE_SUCCESS,
  SELECTED_CHART_SUCCESS,
  UPDATE_CATEGORIE_MULTIPLE_SUCCESS,
  UPDATE_CATEGORIE_MULTIPLE_FAIL,
  CREATE_TOGGLE_SUCCESS,
  SAVE_STATUT_ELEMENT,
  SAVE_VALEUR_ELEMENT,
  GET_STATUT_FILTER,
  GET_EXPANDED_CATEGORIE_ALL,
  SCROLL_POSITION_TABLE,
  SET_EXPANDED_CATEGORIES
} from '../../actions/categories/type';

import {
  UPDATE_Element_SUCCESS,
  CREATE_Element_SUCCESS,
  DELETE_Element_SUCCESS,
  GET_ONLYELEMENT_SUCCESS,
  GET_ONLYELEMENT_FAIL
} from '../../actions/element/type';
import {
  CREATE_OBJECTIVE_SUCCESS,
  LIST_OBJECTIVE_SUCCESS,
  UPDATE_OBJECTIVE_SUCCESS,
  LIST_ALL_OBJECTIVE_SUCCESS,
  DELETE_OBJECTIVE_SUCCESS
} from '../../actions/objective/type';
import { LIST_STATE_FAIL, LIST_STATE_SUCCESS } from '../../actions/state/type';
const initialState = {
  categories: [],
  elements: [],
  categorie: null,
  showLoading: false,
  statutMonth: false,
  editing: false,
  editingObjective: false,
  checkedSynthese: false,
  checkedObjectif: false,
  colorSolde: null,
  dateFilter: null,
  monthFilter: 0,
  objectives: [],
  objectivesAll: [],
  SelectedObjective: null,
  Objectivecategories: null,
  onlyElements: [],
  mode: null,
  chartMode: null,
  state: [],
  toogleCreate: false,
  statutElement: null,
  valeurElement: null,
  getStatutFilter: null,
  expandedCategorieAll: [],
  scrollToTable: null,
  expandedCategories: []
};
const categorieReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ONLYELEMENT_SUCCESS:
      return {
        ...state,
        onlyElements: payload
      };
    case GET_STATUT_FILTER:
      return {
        ...state,
        getStatutFilter: payload
      };
    case CREATE_TOGGLE_SUCCESS:
      return {
        ...state,
        toogleCreate: payload
      };
    case GET_ONLYELEMENT_FAIL:
      return {
        ...state,
        onlyElements: payload
      };
    case LIST_STATE_SUCCESS:
      return {
        ...state,
        state: payload
      };
    case LIST_STATE_FAIL:
      return {
        ...state,
        state: payload
      };
    case SELECTED_MODE_SUCCESS:
      return {
        ...state,
        mode: payload
      };
    case SELECTED_CHART_SUCCESS:
      return {
        ...state,
        chartMode: payload
      };
    case LIST_CATEGORIE_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case SELECTED_OBJECTIF_SUCCESS:
      return {
        ...state,
        SelectedObjective: payload
      };
    case LIST_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objectives: payload
      };
    case MONTH_FILTER_SUCCESS:
      return {
        ...state,
        monthFilter: payload
      };
    case SAVE_STATUT_ELEMENT:
      return {
        ...state,
        statutElement: payload
      };
    case SAVE_VALEUR_ELEMENT:
      return {
        ...state,
        valeurElement: payload
      };
    case CHECKED_SYNTHESE_SUCCESS:
      return {
        ...state,
        checkedSynthese: payload
      };
    case CHECKED_OBJECTIF_SUCCESS:
      return {
        ...state,
        checkedObjectif: payload
      };
    case LIST_CATEGORIE_COMPACT:
      return {
        ...state,
        categories: payload
      };
    case LIST_CATEGORIE_COMPACT_STATUT:
      return {
        ...state,
        statutMonth: payload
      };
    case LIST_CATEGORIE_All:
      return {
        ...state,
        categoriesAll: payload
      };
    case LIST_ELEMENTS_SUCCESS:
      return {
        ...state,
        elements: payload
      };
    case CREATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objectives: payload
      };
    case DELETE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objectives: payload
      };
    case UPDATE_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objectives: payload
      };
    case LIST_ALL_OBJECTIVE_SUCCESS:
      return {
        ...state,
        objectivesAll: payload
      };
    case LIST_CATEGORIE_SUCCESS_FINAL:
      return {
        ...state,
        soldeAll: payload
      };
    case LIST_CATEGORIE_FAIL:
      return {
        ...state
      };
    case CREATE_CATEGORIE_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, payload]
      };
    case CREATE_OBJECTIVE_CATEGORIE_SUCCESS:
      return {
        ...state,
        Objectivecategories: payload
      };
    case CREATE_OBJECTIVE_CATEGORIE_FAIL:
      return {
        ...state,
        Objectivecategories: payload
      };
    case CREATE_CATEGORIE_FAIL:
      return {
        ...state,
        createFail: payload
      };

    case GET_CATEGORIE_SUCCESS:
      return {
        ...state,
        categorie: payload
      };
    case GET_CATEGORIE_FAIL:
      return {
        ...state,
        categorie: payload
      };
    case LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: payload
      };
    case UPDATE_Element_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case DELETE_Element_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case CREATE_Element_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case UPDATE_CATEGORIE_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case UPDATE_CATEGORIE_MULTIPLE_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case UPDATE_CATEGORIE_MULTIPLE_FAIL:
      return {
        ...state,
        categories: payload
      };
    case UPDATE_OBJECTIVE_CATEGORIE_SUCCESS:
      return {
        ...state,
        Objectivecategories: payload
      };
    case UPDATE_OBJECTIVE_CATEGORIE_FAIL:
      return {
        ...state,
        Objectivecategories: payload
      };
    case EDITING_ACTION:
      return {
        ...state,
        editing: payload
      };
    case EDITING_OBJECTIVE_ACTION:
      return {
        ...state,
        editingObjective: payload
      };

    case COLOR_SOLDE_ACTION:
      return {
        ...state,
        colorSolde: payload
      };
    case DELETE_CATEGORIE_SUCCESS:
      return {
        ...state,
        categories: payload
      };
    case FILTER_DATE_ACTION:
      return {
        ...state,
        dateFilter: payload
      };
    case GET_EXPANDED_CATEGORIE_ALL:
      return {
        ...state,
        expandedCategorieAll: payload
      };

    case SCROLL_POSITION_TABLE:
      return {
        ...state,
        scrollToTable: payload
      };

    case SET_EXPANDED_CATEGORIES:
      return {
        ...state,
        expandedCategories: [...state.expandedCategories, payload]
      };
  }
  return <div>index</div>;
};

export default categorieReducer;
