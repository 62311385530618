import React from 'react';
import { Input } from 'antd';
import './InputSearch.scss';
import searchIcon from '../../assets/icon/searchIcon.svg';
import Cell from '../CustomCell/CustomCell';

const InputSearch = () => {
  return (
    <>
      <Input placeholder="Recherche" size="large" prefix={<img alt="" src={searchIcon} />} className="search-input" />
    </>
  );
};

export default InputSearch;
