import { Table } from 'antd';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDefaultColumnsElements, nestCategories } from '../../helpers/helpers';
// import Cell from '../CustomCell/CustomCell';
import DragAndDropElements from './DragandDropElement';
import Td from '../Td/Td';
import Thead from '../Thead/Thead';
import './CustomTableElement.css';
import TdElement from './TdElement';
import CellElement from './cell/CellElement';
import { getonlyElements, modeElement } from '../../redux/actions/element';
import { filterDateAction, listCategorie, monthFilter, scrollToPoistionTbale } from '../../redux/actions/categories';

function CustomTableElement({ title, classStyle }) {
  const monthFilterGroup = useSelector((state) => state.categories.monthFilter);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const listsSolde = useSelector((state) => state.categories.soldeAll);
  const modeTable = useSelector((state) => state.categories.mode);
  const getStatutFilter = useSelector((state) => state.categories.getStatutFilter);

  const [dataSource, setDataSource] = React.useState([]);
  const onlyelements = useSelector((state) => state.categories.onlyElements);
  const toogleCreate = useSelector((state) => state.categories.toogleCreate);
  const monthsTotalData = React.useMemo(() => listsSolde || [], [listsSolde]);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  const statutElement = useSelector((state) => state.categories.statutElement);
  const valeurElement = useSelector((state) => state.categories.valeurElement);
  const scrollToTable = useSelector((state) => state.categories.scrollToTable);

  let dateNow = new Date();

  const [startDate, setstartDate] = React.useState(dateNow.getFullYear() + '-' + 1);
  const [endDate, setendDate] = React.useState(dateNow.getFullYear() + '-' + 12);
  const tableRef = React.useRef(null);
  const [scrollPosition, setScrollPosition] = React.useState({ x: 0, y: 0 });
  const dispatch = useDispatch();
  const [months, setMonths] = React.useState([
    'January 2023',
    'February 2023',
    'March 2023',
    'April 2023',
    'May 2023',
    'June 2023',
    'July 2023',
    'August 2023',
    'September 2023',
    'October 2023',
    'November 2023',
    'December 2023'
  ]);

  React.useEffect(() => {
    if (modeTable === 'compta') {
      if (statutElement) {
        dispatch(getonlyElements(userId, 'dateCompta', statutElement, '', startDate, endDate, { filterSolde: getStatutFilter ? getStatutFilter : '' }));
      } else if (valeurElement) {
        dispatch(getonlyElements(userId, 'dateCompta', '', valeurElement, startDate, endDate, { filterSolde: getStatutFilter ? getStatutFilter : '' }));
      } else {
        dispatch(getonlyElements(userId, 'dateCompta', '', '', startDate, endDate, { filterSolde: getStatutFilter ? getStatutFilter : '' }));
      }
    } else {
      dispatch(modeElement('treso'));
      if (statutElement) {
        dispatch(getonlyElements(userId, 'dateTreso', statutElement, '', startDate, endDate, { filterSolde: getStatutFilter ? getStatutFilter : '' }));
      } else if (valeurElement) {
        dispatch(getonlyElements(userId, 'dateTreso', '', valeurElement, startDate, endDate, { filterSolde: getStatutFilter ? getStatutFilter : '' }));
      } else {
        dispatch(getonlyElements(userId, 'dateTreso', '', '', startDate, endDate, { filterSolde: getStatutFilter ? getStatutFilter : '' }));
      }
    }
  }, [toogleCreate, modeTable, dateFilter]);

  React.useEffect(() => {
    if (monthFilterGroup) {
      dispatch(filterDateAction(startDate, endDate, months, startDate, endDate));
      dispatch(monthFilter());
    }
  }, [startDate, endDate, months]);

  React.useEffect(() => {
    setDataSource(onlyelements);
  }, [onlyelements]);

  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  React.useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const renderCell = React.useCallback(
    (props) => {
      return <CellElement {...props} modeTable={modeTable} />;
    },
    [modeTable]
  );

  const defaultColumns = React.useMemo(() => getDefaultColumnsElements(renderCell, title, dateFilter, modeTable), [renderCell, dateFilter, title, modeTable]);

  const columns = React.useMemo(
    () =>
      defaultColumns.map((col) => {
        return {
          ...col,
          onCell: (record) => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            className: col.className
          })
        };
      }),
    [defaultColumns]
  );

  /* React.useEffect(() => {
    const tableContainer = tableRef.current.querySelector('.ant-table-body');

    const handleScroll = () => {
      const scrollX = tableContainer.scrollLeft;
      const scrollY = tableContainer.scrollTop;
      setScrollPosition({ x: scrollX, y: scrollY });
      dispatch(scrollToPoistionTbale({ x: scrollX, y: scrollY }))
    };

    tableContainer.addEventListener('scroll', handleScroll);

    // Scroll to a specific position on component mount
    if(scrollToTable) {
      tableContainer.scrollTo({
        left: scrollToTable.x, // Set the desired horizontal scroll position
        top: scrollToTable.y,
        behavior: 'smooth'
      });
    }
    return () => {
      tableContainer.removeEventListener('scroll', handleScroll);
    };
  }, [modeTable]); */

  return (
    <>
      <Table
        style={{ width: 290 + dateFilter?.monthArray.length * 250 }}
        className="custom-table"
        ref={tableRef}
        components={{
          header: {
            wrapper: (props) => <Thead {...props} monthsTotalData={monthsTotalData} tableWithElements={true} />
          },
          body: {
            wrapper: (props) => <DragAndDropElements {...props} />,
            cell: (props) => <TdElement {...props} />
          }
        }}
        bordered
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        size="small"
        scroll={{
          // x: monthFilterGroup === undefined ? 2500 : 1200,
          y: windowSize.innerWidth < 1900 ? 'calc(100vh - 33vh)' : 'calc(100vh - 25vh)',
          thumb: { width: '50px', background: '#33cc00' }
        }}
      />
    </>
  );
}

export default CustomTableElement;
