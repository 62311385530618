import {
  CREATE_CATEGORIE_SUCCESS,
  CREATE_CATEGORIE_FAIL,
  LIST_CATEGORIE_SUCCESS,
  LIST_CATEGORIE_FAIL,
  LIST_CATEGORIE_All,
  LIST_CATEGORIE_SUCCESS_FINAL,
  UPDATE_CATEGORIE_SUCCESS,
  UPDATE_CATEGORIE_FAIL,
  DELETE_CATEGORIE_SUCCESS,
  DELETE_CATEGORIE_FAIL,
  LIST_ELEMENTS_SUCCESS,
  GET_CATEGORIE_SUCCESS,
  GET_CATEGORIE_FAIL,
  LOADING_TOGGLE_ACTION,
  EDITING_ACTION,
  COLOR_SOLDE_ACTION,
  FILTER_DATE_ACTION,
  LIST_CATEGORIE_COMPACT,
  LIST_CATEGORIE_COMPACT_FAIL,
  LIST_CATEGORIE_COMPACT_STATUT,
  MONTH_FILTER_SUCCESS,
  CHECKED_SYNTHESE_SUCCESS,
  CHECKED_OBJECTIF_SUCCESS,
  SELECTED_OBJECTIF_SUCCESS,
  EDITING_OBJECTIVE_ACTION,
  CREATE_OBJECTIVE_CATEGORIE_SUCCESS,
  CREATE_OBJECTIVE_CATEGORIE_FAIL,
  UPDATE_OBJECTIVE_CATEGORIE_SUCCESS,
  UPDATE_OBJECTIVE_CATEGORIE_FAIL,
  UPDATE_CATEGORIE_MULTIPLE_SUCCESS,
  UPDATE_CATEGORIE_MULTIPLE_FAIL,
  CREATE_TOGGLE_SUCCESS,
  SAVE_STATUT_ELEMENT,
  SAVE_VALEUR_ELEMENT,
  GET_STATUT_FILTER,
  GET_EXPANDED_CATEGORIE_ALL,
  SCROLL_POSITION_TABLE,
  SET_EXPANDED_CATEGORIES
} from './type';

import CategorieService from '../../../services/CategorieService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const createCategorie = (data) => async (dispatch) => {
  try {
    const res = await CategorieService.create(data);

    dispatch({
      type: CREATE_CATEGORIE_SUCCESS,
      payload: res.data
    });
    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch(loadingToggleAction(false));

    dispatch({
      type: CREATE_CATEGORIE_FAIL,
      payload: message
    });
    toast.error('vous devez nommer la catégorie');

    return Promise.resolve(error);
  }
};

export const createObjectiveWithCategory = (data) => async (dispatch) => {
  try {
    const res = await CategorieService.createObjectiveWithCategory(data);

    dispatch({
      type: CREATE_OBJECTIVE_CATEGORIE_SUCCESS,
      payload: res.data
    });
    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: CREATE_OBJECTIVE_CATEGORIE_FAIL,
      payload: message
    });

    return Promise.resolve(error);
  }
};

export const updateObjectiveWithCategory = (id, data, title, startDate, endDate, month, startDateSynthese, endDateSynthese) => async (dispatch) => {
  try {
    const res = await CategorieService.updateObjectiveWithCategory(id, data, title, startDate, endDate, month, startDateSynthese, endDateSynthese);

    dispatch({
      type: UPDATE_OBJECTIVE_CATEGORIE_SUCCESS,
      payload: res.data.copie?.filter((el) => el?.type === title || el?.type === null)
    });

    dispatch({
      type: LIST_CATEGORIE_SUCCESS,
      payload: res.data.copie?.filter((el) => el?.type === title || el?.type === null)
    });

    return Promise.resolve(res.data);
  } catch (error) {
    const message = error.response && error.response.data && error.response.data.message;

    dispatch({
      type: UPDATE_OBJECTIVE_CATEGORIE_FAIL,
      payload: message
    });

    return Promise.resolve(error);
  }
};

export const listCategorie = (params, startDate, endDate, month, startDateSynthese, endDateSynthese, userId, SelectedObjective) => (dispatch) => {
  if (params === 'treso') {
    return CategorieService.listTreso(startDate, endDate, month, startDateSynthese, endDateSynthese, userId, SelectedObjective).then(
      (response) => {
        dispatch({
          type: LIST_CATEGORIE_SUCCESS,
          payload: response.data.copie
        });

        dispatch({
          type: LIST_ELEMENTS_SUCCESS,
          payload: response.data.elements
        });

        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

        dispatch({
          type: LIST_CATEGORIE_FAIL,
          payload: message
        });

        return Promise.reject();
      }
    );
  } else if (params === 'compta') {
    return CategorieService.listCompta(startDate, endDate, month, startDateSynthese, endDateSynthese, userId, SelectedObjective).then(
      (response) => {
        dispatch({
          type: LIST_CATEGORIE_SUCCESS,
          payload: response.data.copie
        });

        dispatch({
          type: LIST_ELEMENTS_SUCCESS,
          payload: response.data.elements
        });

        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

        dispatch({
          type: LIST_CATEGORIE_FAIL,
          payload: message
        });

        return Promise.reject();
      }
    );
  }
};

export const listSolde = (params, startDate, endDate, month, startDateSynthese, endDateSynthese, userId, SelectedObjective, getStatutFilter) => (dispatch) => {
  if (params === 'treso') {
    return CategorieService.listSoldeTrseo(startDate, endDate, month, startDateSynthese, endDateSynthese, userId, SelectedObjective, getStatutFilter).then(
      (response) => {
        dispatch({
          type: LIST_CATEGORIE_SUCCESS_FINAL,
          payload: response.data.totalSoldesMonth
        });
        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data.ArraySoldes && error.response.data.ArraySoldes.message) || error.message || error.toString();

        dispatch({
          type: LIST_CATEGORIE_FAIL,
          payload: message
        });

        return Promise.reject();
      }
    );
  } else if (params === 'compta') {
    return CategorieService.listSoldeCompta(startDate, endDate, month, startDateSynthese, endDateSynthese, userId, SelectedObjective, getStatutFilter).then(
      (response) => {
        dispatch({
          type: LIST_CATEGORIE_SUCCESS_FINAL,
          payload: response.data.totalSoldesMonth
        });

        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data.ArraySoldes && error.response.data.ArraySoldes.message) || error.message || error.toString();

        dispatch({
          type: LIST_CATEGORIE_FAIL,
          payload: message
        });

        return Promise.reject();
      }
    );
  }
};

export const listCategoriePopup = (userId) => (dispatch) => {
  return CategorieService.listCategoriePopup(userId).then(
    (response) => {
      dispatch({
        type: LIST_CATEGORIE_All,
        payload: response.data
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: LIST_CATEGORIE_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const updateCategorie = (id, data, startDate, endDate, month, startDateSynthese, endDateSynthese, mode) => (dispatch) => {
  return CategorieService.update(id, data, startDate, endDate, month, startDateSynthese, endDateSynthese).then(
    (response) => {
      dispatch(loadingToggleAction(false));
      console.log(
        response.data.copie,
        response.data.copie.map((el) => el.type),
        mode
      );
      console.log(response.data.copie?.filter((el) => el?.type === mode || el?.type === null));
      dispatch({
        type: UPDATE_CATEGORIE_SUCCESS,
        payload: response.data.copie?.filter((el) => el?.type === mode || el?.type === null)
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data.copie && error.response.data.copie.message) || error.message || error.toString();

      dispatch({
        type: UPDATE_CATEGORIE_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const updateCategorieMultiple = (userId, data, startDate, endDate, month, startDateSynthese, endDateSynthese) => (dispatch) => {
  return CategorieService.updateMultiple(userId, data, startDate, endDate, month, startDateSynthese, endDateSynthese).then(
    (response) => {
      dispatch({
        type: UPDATE_CATEGORIE_MULTIPLE_SUCCESS,
        payload: response.data.copie
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data.copie && error.response.data.copie.message) || error.message || error.toString();

      dispatch({
        type: UPDATE_CATEGORIE_MULTIPLE_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const deleteCategorie = (id, soldeFilter, startDate, endDate) => (dispatch) => {
  return CategorieService.deleteCategorie(id, soldeFilter, startDate, endDate).then(
    (response) => {
      dispatch({
        type: DELETE_CATEGORIE_SUCCESS,
        payload: response.data.data.copie
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data.copie && error.response.data.copie.message) || error.message || error.toString();

      dispatch({
        type: DELETE_CATEGORIE_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const deleteCategorieOne = (userId, data, soldeFilter, startDate, endDate, startDateSynthese, endDateSynthese, mode) => (dispatch) => {
  return CategorieService.deleteCategorieOne(userId, data, soldeFilter, startDate, endDate, startDateSynthese, endDateSynthese).then(
    (response) => {
      dispatch({
        type: DELETE_CATEGORIE_SUCCESS,
        payload: response.data.copie?.filter((el) => el?.type === mode || el?.type === null)
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data.copie && error.response.data.copie.message) || error.message || error.toString();

      dispatch({
        type: DELETE_CATEGORIE_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const getCategory = (id) => (dispatch) => {
  return CategorieService.get(id).then(
    (response) => {
      dispatch({
        type: GET_CATEGORIE_SUCCESS,
        payload: response.data
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

      dispatch({
        type: GET_CATEGORIE_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const loadingToggleAction = (status) => (dispatch) => {
  dispatch({
    type: LOADING_TOGGLE_ACTION,
    payload: status
  });
};

export const colorSoldeTotal = (color) => (dispatch) => {
  dispatch({
    type: COLOR_SOLDE_ACTION,
    payload: color
  });
};

export const editingAction = (status, id, type) => (dispatch) => {
  if (id) {
    dispatch({
      type: EDITING_ACTION,
      payload: { status, id, type }
    });
  } else {
    dispatch({
      type: EDITING_ACTION,
      payload: { status, type }
    });
  }
};

export const editObjectiveMontant = (status, id, value) => (dispatch) => {
  dispatch({
    type: EDITING_OBJECTIVE_ACTION,
    payload: { status, id, value }
  });
};
export const filterDateAction = (startDate, endDate, monthArray, startDateSynthese, endDateSynthese) => (dispatch) => {
  if (monthArray) {
    dispatch({
      type: FILTER_DATE_ACTION,
      payload: { startDate, endDate, monthArray, startDateSynthese, endDateSynthese }
    });
  } else {
    dispatch({
      type: FILTER_DATE_ACTION,
      payload: { startDate, endDate, startDateSynthese, endDateSynthese }
    });
  }
};
export const setExpandedCategories = (cat) => (dispatch) => {
  dispatch({
    type: SET_EXPANDED_CATEGORIES,
    payload: cat
  });
};
export const compactCategory = (title, soldeFilter, startDate, endDate, month, Satut) => (dispatch) => {
  return CategorieService.compactCatgeory(title, soldeFilter, startDate, endDate, month).then(
    (response) => {
      dispatch({
        type: LIST_CATEGORIE_COMPACT,
        payload: response.data.copie
      });

      dispatch({
        type: LIST_CATEGORIE_COMPACT_STATUT,
        payload: Satut
      });

      dispatch({
        type: LIST_CATEGORIE_SUCCESS_FINAL,
        payload: response.data.totalSoldesMonth
      });

      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data.ArraySoldes && error.response.data.ArraySoldes.message) || error.message || error.toString();

      dispatch({
        type: LIST_CATEGORIE_COMPACT_FAIL,
        payload: message
      });

      return Promise.reject();
    }
  );
};

export const monthFilter = (value) => (dispatch) => {
  dispatch({
    type: MONTH_FILTER_SUCCESS,
    payload: value
  });
};

export const checkedSynthse = (value) => (dispatch) => {
  dispatch({
    type: CHECKED_SYNTHESE_SUCCESS,
    payload: value
  });
};

export const checkedObjectif = (value) => (dispatch) => {
  dispatch({
    type: CHECKED_OBJECTIF_SUCCESS,
    payload: value
  });
};

export const ObjectiveSelected = (value) => (dispatch) => {
  dispatch({
    type: SELECTED_OBJECTIF_SUCCESS,
    payload: value
  });
};

export const createToogle = (value) => (dispatch) => {
  dispatch({
    type: CREATE_TOGGLE_SUCCESS,
    payload: value
  });
};

export const SavestatutElementValue = (value) => (dispatch) => {
  dispatch({
    type: SAVE_STATUT_ELEMENT,
    payload: value
  });
};

export const SaveValeurElementValue = (value) => (dispatch) => {
  dispatch({
    type: SAVE_VALEUR_ELEMENT,
    payload: value
  });
};

export const getStatutFilter = (value) => (dispatch) => {
  dispatch({
    type: GET_STATUT_FILTER,
    payload: value
  });
};

export const exapndedCategoriesAll = (value) => (dispatch) => {
  dispatch({
    type: GET_EXPANDED_CATEGORIE_ALL,
    payload: value
  });
};

export const scrollToPoistionTbale = (value) => (dispatch) => {
  dispatch({
    type: SCROLL_POSITION_TABLE,
    payload: value
  });
};
