import React from 'react';
import RouterComponent from './routes';

import './App.scss';
function App() {

  return (
    <React.Fragment>
      <RouterComponent />
    </React.Fragment>
  );
}

export default App;
