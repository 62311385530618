import * as React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getQueryStringParams } from '../../helpers/helpers';
import ajoutElement from '../../assets/icon/ajoutElement.svg';
import gear from '../../assets/icon/Gear.svg';
import prev from '../../assets/icon/prev.svg';
import engage from '../../assets/icon/engage.svg';
import pointe from '../../assets/icon/pointe.svg';
import trash from '../../assets/icon/Trash.svg';
import reel from '../../assets/icon/reel.svg';
import simulation from '../../assets/icon/simulation.svg';
import edit from '../../assets/icon/edit.svg';

import { QuestionCircleOutlined } from '@ant-design/icons';

import './CustomCell.css';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, Button, Input, Tooltip, Popconfirm, Form } from 'antd';
import PopupCustomer from '../PopupCustomer/PopupCustomer';
import { chekDate } from '../../helpers/checkDate';
import { createElementbydate, deleteElement, updateElement } from '../../redux/actions/element';
import {
  createToogle,
  editingAction,
  editObjectiveMontant,
  exapndedCategoriesAll,
  listCategorie,
  listSolde,
  updateCategorie
} from '../../redux/actions/categories';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRef } from 'react';
function Cell({
  monthTotal,
  monthElements,
  dragPrefix,
  indexMultiplier,
  categoryName,
  expanded,
  title,
  expandedCategories,
  setExpandedCategories,
  synthese,
  categories
}) {
  const is_special = categories.is_special;
  const elements = useSelector((state) => state.categories.elements);
  const getStatutFilter = useSelector((state) => state.categories.getStatutFilter);

  const editing = useSelector((state) => state.categories.editing);
  const listsCategory = useSelector((state) => state.categories.categories);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const monthFilter = useSelector((state) => state.categories.monthFilter);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  const SelectedObjective = useSelector((state) => state.categories.SelectedObjective);
  const checkedSynthese = useSelector((state) => state.categories.checkedSynthese);
  const checkedObjectif = useSelector((state) => state.categories.checkedObjectif);

  const [typeElement, setTypeElement] = React.useState('');
  const [editElement, setEditElement] = React.useState({ status: false });
  const [montant, setMontant] = React.useState('');
  const [libelle, setLibelle] = React.useState('');
  const [testLibelle, setTestLibelle] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const [hoverId, setHoverId] = React.useState(null);
  const [isPopverVisible, setIsPopverVisible] = React.useState(false);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [elementUpdate, setElementUpdate] = React.useState('');
  const [categoryElementName, setCategoryyElementName] = React.useState('');
  const [updateElementPop, setUpdateElementPop] = React.useState(false);
  const [months, setMonths] = React.useState([
    'January 2023',
    'February 2023',
    'March 2023',
    'April 2023',
    'May 2023',
    'June 2023',
    'July 2023',
    'August 2023',
    'September 2023',
    'October 2023',
    'November 2023',
    'December 2023'
  ]);

  const dispatch = useDispatch();
  let location = useLocation();
  const inputRef = useRef(null);
  /**
   *
   * @month variable is so important
   * We need it to know if the cell contains
   * Category name or elements
   * month variable may contains CATEGORY or a month name like -> January
   * With first letter Capitalized
   */
  const { month } = getQueryStringParams(dragPrefix);

  React.useEffect(() => {
    inputRef?.current?.focus();
  });

  const handleMouseEnter = (e) => {
    setHoverId(e.target.id);
    setHover(true);
  };
  const handleMouseLeave = async () => {
    setHoverId(null);
    setHover(false);
  };
  const checkElement = async () => {
    await elements?.map(async (el) => {
      if (el?.libelle?.length === 0 || el?.montant?.length == 0) {
        await dispatch(
          deleteElement(
            el.id,
            title,
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese,
            {
              filterSolde: getStatutFilter ? getStatutFilter : ''
            },
            SelectedObjective?.id
          )
        );
      }
    });
  };
  // Add Element Type Simulation in table
  const addElementSimulation = async () => {
    await checkElement();
    setExpandedCategories(() => {
      const index = expandedCategories.findIndex((ec) => ec === Number(indexMultiplier));
      if (index > -1 && !expanded) return expandedCategories.filter((ec) => ec !== Number(indexMultiplier));
      else return [...expandedCategories, Number(indexMultiplier)];
    });

    /*     const index = expandedCategories.findIndex((ec) => ec === Number(indexMultiplier));
    if (index > -1) dispatch(exapndedCategoriesAll(expandedCategories.filter((ec) => ec !== Number(indexMultiplier))));
    else dispatch(exapndedCategoriesAll([...expandedCategories, Number(indexMultiplier)])); */

    const dataelement = {
      libelle: '',
      montant: 0,
      dateTreso: chekDate(month),
      dateCompta: chekDate(month),
      categorieId: indexMultiplier,
      type: 'Simulation',
      statutId: 1,
      userId: userId
    };

    if (title === 'Trésorerie') {
      await dispatch(
        createElementbydate(
          dataelement,
          'treso',
          dateFilter.startDate,
          dateFilter.endDate,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          SelectedObjective?.id
        )
      );
      await dispatch(
        listCategorie('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
      await dispatch(
        listSolde('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    } else if (title === 'Comptabilité') {
      await dispatch(
        createElementbydate(
          dataelement,
          'compta',
          dateFilter.startDate,
          dateFilter.endDate,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          SelectedObjective?.id
        )
      );
      await dispatch(
        listCategorie('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
      await dispatch(
        listSolde('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
      );
    }

    setIsPopverVisible(!isPopverVisible);
  };

  // Function hide popver Element
  const hide = () => {
    setIsPopverVisible(false);
  };

  // Add Element Type Relle
  const addElementRelle = async () => {
    setTypeElement('Relle');
    setIsPopverVisible(false);
    setIsModalVisible(true);
  };
  const content = () => {
    return (
      <div className="popup-ajouterEcriture">
        <Button
          className="simulation"
          value={'simulation'}
          onClick={() => {
            addElementSimulation();
          }}>
          DE SIMULATION
        </Button>

        <Button
          className="relle"
          value={'relle'}
          onClick={() => {
            addElementRelle();
          }}>
          RELLE
        </Button>
      </div>
    );
  };

  // Function Delete Element
  const handleDeleteElement = async (idElement) => {
    await dispatch(
      deleteElement(
        idElement,
        title,
        dateFilter.startDate,
        dateFilter.endDate,
        monthFilter,
        dateFilter.startDateSynthese,
        dateFilter.endDateSynthese,
        {
          filterSolde: getStatutFilter ? getStatutFilter : ''
        },
        SelectedObjective?.id
      )
    ).then(() => {
      if (title === 'Trésorerie') {
        dispatch(
          listCategorie('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
        dispatch(
          listSolde('treso', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      } else if (title === 'Comptabilité') {
        dispatch(
          listCategorie('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
        dispatch(
          listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      }
    });
  };

  const changeStatut = async (idElement, statut) => {
    if (title == 'Comptabilité') {
      // console.log('title:', title);
      await dispatch(
        updateElement(
          idElement,
          { type: statut, userId: userId },
          title,
          dateFilter.startDate,
          dateFilter.endDate,
          monthFilter,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          { filterSolde: getStatutFilter ? getStatutFilter : '' },
          SelectedObjective?.id
        )
      );
    } else if (title == 'Trésorerie') {
      if (statut === 'PRÉVISIONNEL') {
        await dispatch(
          updateElement(
            idElement,
            { statutId: 1, userId: userId },
            title,
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese,
            { filterSolde: getStatutFilter ? getStatutFilter : '' },
            SelectedObjective?.id
          )
        );
      } else if (statut === 'ENGAGÉ') {
        await dispatch(
          updateElement(
            idElement,
            { statutId: 2, userId: userId },
            title,
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese,
            { filterSolde: getStatutFilter ? getStatutFilter : '' },
            SelectedObjective?.id
          )
        );
      } else if (statut === 'POINTÉ') {
        await dispatch(
          updateElement(
            idElement,
            { statutId: 3, userId: userId },
            title,
            dateFilter.startDate,
            dateFilter.endDate,
            monthFilter,
            dateFilter.startDateSynthese,
            dateFilter.endDateSynthese,
            { filterSolde: getStatutFilter ? getStatutFilter : '' },
            SelectedObjective?.id
          )
        );
      }
    }
  };
  const findElement = async (idElement) => {
    const result = await elements?.filter((el) => {
      return el.id === idElement;
    });

    const category = await listsCategory?.filter((el) => {
      return el.id === result[0]?.categorieId;
    });
    setCategoryyElementName(category[0]?.name);
    await setElementUpdate(result);
  };

  const updateElementGraphicsMode = async (idElement) => {
    await findElement(idElement);
    await setUpdateElementPop(true);
    setIsModalVisible(true);
  };

  const contentPopover = (idElement, typeElement, statut) => {
    if (location.pathname == '/table-compta') {
      return (
        <div className="contentPopver">
          {statut === 1 && (
            <div className="contentStatut">
              <Tooltip title="RELLE">
                <img src={reel} onClick={() => changeStatut(idElement, 'Relle')} />
              </Tooltip>
              <Tooltip title="SIMULATION">
                <img src={simulation} onClick={() => changeStatut(idElement, 'Simulation')} />
              </Tooltip>
            </div>
          )}
          <div className="contentDelete">
            <Tooltip title="Supprimer">
              <div className="contentDelete__delete">
                <Popconfirm
                  placement="bottomRight"
                  title="Êtes-vous sûr？"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: 'red'
                      }}
                    />
                  }
                  onConfirm={() => handleDeleteElement(idElement)}>
                  <div className="btdelete">
                    <img src={trash} />
                  </div>
                </Popconfirm>
              </div>
            </Tooltip>
          </div>
          <div className="btdelete">
            <Tooltip title="Modifier">
              <button className="btedit" onClick={() => updateElementGraphicsMode(idElement)}>
                <img src={edit} />
              </button>
            </Tooltip>
          </div>
        </div>
      );
    } else if (location.pathname == '/') {
      return (
        <div className="contentPopver">
          {typeElement === 'Relle' && (
            <div className="contentStatut">
              <Tooltip title="PRÉVISIONNEL">
                <img src={prev} onClick={() => changeStatut(idElement, 'PRÉVISIONNEL')} />
              </Tooltip>
              <Tooltip title="ENGAGÉ">
                <img src={engage} onClick={() => changeStatut(idElement, 'ENGAGÉ')} />
              </Tooltip>
              <Tooltip title="POINTÉ">
                <img src={pointe} onClick={() => changeStatut(idElement, 'POINTÉ')} />
              </Tooltip>
            </div>
          )}
          <div className="contentDelete">
            <Tooltip title="Supprimer">
              <div className="contentDelete__delete">
                <Popconfirm
                  placement="bottomRight"
                  title="Êtes-vous sûr？"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: 'red'
                      }}
                    />
                  }
                  onConfirm={() => handleDeleteElement(idElement)}>
                  <div className="btdelete">
                    <img src={trash} />
                  </div>
                </Popconfirm>
              </div>
            </Tooltip>
          </div>
          <div className="btdelete">
            <Tooltip title="Modifier">
              <button className="btedit" onClick={() => updateElementGraphicsMode(idElement)}>
                <img src={edit} />
              </button>
            </Tooltip>
          </div>
        </div>
      );
    }
  };

  function parseAmount(amount) {
    // Replace commas with periods
    amount = amount.replace(/,/g, '.');

    // Convert the string to a float
    try {
      return parseFloat(amount);
    } catch (error) {
      // Handle any parsing errors
      console.error('Error parsing amount:', error);
      return NaN;
    }
  }

  const handleUpdateEelement = async (e, key) => {
    const data = {
      libelle: libelle,
      montant: parseAmount(e.target.value) || 0,
      userId: userId
    };
    if (key && Object.keys(data).length > 1) {
      await dispatch(
        updateElement(
          key,
          data,
          title,
          dateFilter.startDate,
          dateFilter.endDate,
          monthFilter,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          {
            filterSolde: getStatutFilter ? getStatutFilter : ''
          },
          SelectedObjective?.id
        )
      );

      try {
        if (title === 'Trésorerie') {
          await dispatch(
            listCategorie('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
          );
          await dispatch(
            listSolde('treso', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
          );
        } else if (title === 'Comptabilité') {
          await dispatch(
            listCategorie('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
          );
          await dispatch(
            listSolde('compta', '', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleUpdateEelementMontant = async (e, key) => {
    const data = {
      montant: parseAmount(e.target.value),
      userId: userId
    };
    if (key) {
      await dispatch(
        updateElement(
          key,
          data,
          title,
          dateFilter.startDate,
          dateFilter.endDate,
          monthFilter,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          {
            filterSolde: getStatutFilter ? getStatutFilter : ''
          },
          SelectedObjective?.id
        )
      );
      if (title === 'Trésorerie') {
        await dispatch(
          listCategorie('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
        await dispatch(
          listSolde('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      } else if (title === 'Comptabilité') {
        await dispatch(
          listCategorie('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
        await dispatch(
          listSolde('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      }
    }
  };

  const handleUpdateEelementLibelle = async (e, key) => {
    const data = {
      libelle: e.target.value,
      userId: userId
    };
    if (key) {
      await dispatch(
        updateElement(
          key,
          data,
          title,
          dateFilter.startDate,
          dateFilter.endDate,
          monthFilter,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          {
            filterSolde: getStatutFilter ? getStatutFilter : ''
          },
          SelectedObjective?.id
        )
      );
      if (title === 'Trésorerie') {
        await dispatch(
          listCategorie('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
        await dispatch(
          listSolde('treso', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      } else if (title === 'Comptabilité') {
        await dispatch(
          listCategorie('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
        await dispatch(
          listSolde('compta', dateFilter.startDate, dateFilter.endDate, monthFilter, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId)
        );
      }
    }
  };

  const handleUpdateCatgeory = (e) => {
    dispatch(editingAction(false));
    dispatch(
      updateCategorie(
        editing?.id,
        { name: e.target.value, userId: userId },
        dateFilter.startDate,
        dateFilter.endDate,
        monthFilter,
        dateFilter.startDateSynthese,
        dateFilter.endDateSynthese,
        location.pathname === '/table-compta' ? 'compta' : 'treso'
      )
    ).then(() => {
      dispatch(
        listCategorie(
          location.pathname === '/table-compta' ? 'compta' : 'treso',
          dateFilter.startDate,
          dateFilter.endDate,
          monthFilter,
          dateFilter.startDateSynthese,
          dateFilter.endDateSynthese,
          userId,
          SelectedObjective?.id
        )
      );
    });
  };

  const toggleEditElement = (e) => {
    setEditElement({ status: true, id: e });
  };

  const editObjective = (e, id, value) => {
    dispatch(editObjectiveMontant(true, id, value));
  };

  const filterObjective = (array, selectedObj) => {
    let filtred = array?.filter((el) => el.id == selectedObj);
    if (filtred?.length > 0) {
      let objective = filtred[0];

      return (
        <p key={Number(indexMultiplier)} onClick={(e) => editObjective(e, Number(indexMultiplier), objective?.Categorie_Objective?.montant)}>
          {objective?.Categorie_Objective?.montant?.toLocaleString('fi-FI', {
            style: 'currency',
            currency: 'EUR'
          })}
        </p>
      );
    } else {
      return (
        <p key={Number(indexMultiplier)} onClick={(e) => editObjective(e, Number(indexMultiplier), 0)}>
          0{' '}
        </p>
      );
    }
  };

  React.useEffect(() => {
    var elementstd = document.querySelectorAll('.elementCell');
    const tdCell = document.getElementsByClassName('table-td')[1].offsetWidth;

    for (var i = 0; i < elementstd.length; i++) {
      elementstd[i].style.width = `${tdCell - 20}px`;
      if (checkedSynthese) {
        elementstd[i].style.width = `${tdCell - 20}px`;
      }
      if (checkedObjectif) {
        elementstd[i].style.width = `${tdCell - 20}px`;
      }
    }
  }, [hover, hoverId, checkedSynthese, checkedObjectif]);

  function truncate(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    } else {
      return str;
    }
  }

  function getClassName(title, snapshot, me, is_special) {
    if (snapshot.isDragging) {
      return 'ghosting';
    }

    if (title === 'Comptabilité') {
      if (me.type === 'Simulation' && me.label) {
        return is_special ? 'elementPointedWithBorder' : '';
      }
      if (me.statut === 3 && me.type === 'Relle') {
        return is_special ? 'elementPointedDark elementPointedWithBorder' : 'elementPointedDark';
      } else if (me.statut === 2 || me.statut === 1) {
        return is_special && me.label ? 'elementgraphe elementPointedWithBorder' : 'elementgraphe';
      } else {
        return is_special ? 'elementgrapheCompta elementPointedWithBorder' : 'elementgrapheCompta';
      }
    }

    if (title === 'Trésorerie') {
      if (me.statut === 3 && me.type === 'Relle') {
        return is_special ? 'elementPointed elementPointedWithBorder' : 'elementPointed';
      } else if (me.statut === 2 && me.type === 'Relle') {
        return is_special ? 'elementEngaged elementPointedWithBorder' : 'elementEngaged';
      } else if (me.statut === 1 && me.type === 'Relle') {
        return is_special ? 'elementProvisional elementPointedWithBorder' : 'elementProvisional';
      }
    }
    if (is_special && me.label && !editElement.status && me.id !== editElement.id) {
      return 'elementPointedWithBorder';
    }
    return '';
  }
  const renderDraggable = React.useCallback(
    (provided, snapshot) => {
      // console.log({ provided, snapshot });
      const droppableProps = getQueryStringParams(provided?.droppableProps['data-rbd-droppable-id']);
      // console.log(droppableProps);

      if (month === 'CATEGORY') {
        return (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`elements ${snapshot.isDraggingOver ? (title === 'Trésorerie' ? 'dragging-over-treso' : 'dragging-over-compta') : ''}`}>
            <Draggable draggableId={dragPrefix} index={Number(indexMultiplier)}>
              {(provided, snapshot) => (
                <div
                  className={`elementCat ${snapshot.isDragging ? 'ghosting' : ''}`}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}>
                  {editing && editing?.status && editing?.id == Number(indexMultiplier) ? (
                    <>
                      <Form.Item>
                        <Input onPressEnter={handleUpdateCatgeory} onBlur={handleUpdateCatgeory} defaultValue={categoryName} />
                      </Form.Item>
                    </>
                  ) : (
                    <span id={Number(indexMultiplier)}>{truncate(categoryName, 15)}</span>
                  )}
                </div>
              )}
            </Draggable>
          </div>
        );
      }
      return (
        <div
          className={`elements ${snapshot.isDraggingOver ? (title === 'Trésorerie' ? 'dragging-over-treso' : 'dragging-over-compta') : ''}`}
          {...provided.droppableProps}
          ref={provided.innerRef}
          onMouseEnter={(e) => handleMouseEnter(e)}
          onMouseLeave={() => handleMouseLeave()}>
          {month === 'SYNTHESE' ? (
            <p>
              {synthese &&
                synthese?.toLocaleString('fi-FI', {
                  style: 'currency',
                  currency: 'EUR'
                })}
            </p>
          ) : month === 'OBJECTIF' ? (
            categories && filterObjective(categories?.objectives, SelectedObjective?.id)
          ) : (
            <p>
              {monthTotal &&
                monthTotal?.toLocaleString('fi-FI', {
                  style: 'currency',
                  currency: 'EUR'
                })}
            </p>
          )}

          {hover && !monthElements?.length && month !== 'OBJECTIF' && month !== 'SYNTHESE' && (
            <div className="ajoutElementCellCat">
              {monthFilter === undefined && !JSON.parse(droppableProps?.is_special) ? (
                <div className={'elementCell'}>
                  <Popover overlayClassName="popover-add-element" placement="right" title={'Ajouter element'} content={content()} trigger="click">
                    <img src={ajoutElement} />
                  </Popover>
                  <hr width="100%" color="black" size="2" />
                </div>
              ) : (
                <div className={'elementCell'}>
                  <img src={ajoutElement} onClick={addElementRelle} />
                  <hr width="100%" color="black" size="2" />
                </div>
              )}
            </div>
          )}

          {expanded && (
            <>
              {monthElements?.length ? (
                monthElements?.map((me, index) => {
                  return (
                    <Draggable
                      key={index}
                      draggableId={dragPrefix + '&index=' + index + '&type=' + me.type + '&statut=' + me.statut}
                      index={indexMultiplier * index}>
                      {(provided, snapshot) => (
                        <div
                          id={me.id}
                          onMouseEnter={(e) => handleMouseEnter(e)}
                          onMouseLeave={() => handleMouseLeave()}
                          onDoubleClick={() => {
                            toggleEditElement(me.id);
                          }}
                          className={`element ${getClassName(title, snapshot, me, is_special)}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          {editElement.status && me.id == editElement.id ? (
                            <div className="addElement">
                              <Input
                                name="libelle"
                                onPressEnter={(e) => {
                                  handleUpdateEelementLibelle(e, me.id);
                                }}
                                defaultValue={me.label.toString()}
                              />
                              <Input
                                name="montant"
                                onPressEnter={(e) => {
                                  handleUpdateEelementMontant(e, me.id);
                                }}
                                defaultValue={me.value.toString()}
                              />
                            </div>
                          ) : !me.label ? (
                            <div className="addElement">
                              <Input
                                name="libelle"
                                onBlur={(e) => {
                                  setLibelle(e.target.value);
                                }}
                                ref={inputRef}
                                onPressEnter={(e) => {
                                  setLibelle(e.target.value);
                                }}
                                onChange={(e) => {
                                  setLibelle(e.target.value);
                                }}
                              />
                              <Input
                                name="montant"
                                onBlur={(e) => {
                                  handleUpdateEelement(e, me.id);
                                }}
                                onPressEnter={(e) => {
                                  handleUpdateEelement(e, me.id);
                                }}
                              />
                            </div>
                          ) : (
                            <>
                              <div>{me.label.length > 12 ? me.label?.slice(0, 12) + '...' : me.label}</div>
                              <div>
                                {Number(me.value).toString().length > 8
                                  ? Number(me.value)
                                      .toLocaleString('fi-FI', {
                                        style: 'currency',
                                        currency: 'EUR'
                                      })
                                      .toString()
                                      .slice(0, -5) + '...'
                                  : Number(me.value).toLocaleString('fi-FI', {
                                      style: 'currency',
                                      currency: 'EUR'
                                    })}
                              </div>
                            </>
                          )}
                          {hover &&
                          me.id == hoverId &&
                          me.label &&
                          me.montant?.length != 0 &&
                          !editElement.status &&
                          month !== 'OBJECTIF' &&
                          month !== 'SYNTHESE' ? (
                            <div className="ajoutElementCell">
                              <div className={'elementCell'}>
                                <>
                                  {monthFilter === undefined && !JSON.parse(droppableProps?.is_special) ? (
                                    <Popover
                                      overlayClassName="popover-add-element"
                                      placement="right"
                                      title={'Ajouter element'}
                                      content={content}
                                      trigger="click">
                                      <img src={ajoutElement} />
                                    </Popover>
                                  ) : (
                                    <div className={'elementCell'}>
                                      <img src={ajoutElement} onClick={addElementRelle} />
                                      <hr width="100%" color="black" size="2" />
                                    </div>
                                  )}

                                  <hr width="100%" color="black" size="2" />
                                </>

                                <Popover placement="right" content={() => contentPopover(me.id, me.type, me.statut)} trigger="click">
                                  <img src={gear} />
                                </Popover>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })
              ) : (
                <span className="drag-and-drop"></span>
              )}
            </>
          )}

          <div style={{ display: 'none' }}>{provided.placeholder}</div>
        </div>
      );
    },
    [categoryName, dragPrefix, expanded, indexMultiplier, month, monthElements, monthTotal, hover, hoverId, editing, libelle, editElement, categories]
  );

  return (
    <>
      <Droppable droppableId={dragPrefix}>
        {(provided, snapshot) => (
          <>
            {renderDraggable(provided, snapshot)}
            <span style={{ display: 'none' }}>{provided.placeholder}</span>
          </>
        )}
      </Droppable>
      {isModalVisible && (
        <PopupCustomer
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setIsPopverVisible={setIsPopverVisible}
          category={categoryName || categoryElementName}
          categoryId={indexMultiplier}
          date={chekDate(month)}
          elementUpdate={elementUpdate}
          typeEcritureDefault={typeElement}
          updateElementPop={updateElementPop}
          setUpdateElementPop={setUpdateElementPop}
          title={title}
          months={dateFilter?.monthArray}
        />
      )}
    </>
  );
}

export default Cell;
