import React, { useState, useEffect, useRef } from 'react';
import './Header.scss';
import pointeTreso from '../../assets/icon/pointeTreso.svg';
import { DatePicker, Popover, Button, Tooltip, Select, Modal, Switch, Divider, Space, Input } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import kwanto from '../../assets/image/KWANTO.svg';
import filter from '../../assets/icon/filtre.svg';
import person from '../../assets/icon/user.svg';
import modeTreso from '../../assets/icon/modeTreso.svg';
import modeCompta from '../../assets/icon/modeCompta.svg';
import prev from '../../assets/icon/prev.svg';
import engage from '../../assets/icon/engage.svg';
import pointe from '../../assets/icon/pointe.svg';
import simulation from '../../assets/icon/simulation.svg';
import reel from '../../assets/icon/reel.svg';
import add from '../../assets/icon/add.svg';
import exportTable from '../../assets/icon/export.svg';
import InputSearch from '../InputSearch/InputSearch';
import PopupCustomer from '../PopupCustomer/PopupCustomer';
import {
  checkedObjectif,
  checkedSynthse,
  colorSoldeTotal,
  filterDateAction,
  getStatutFilter,
  listCategorie,
  listSolde,
  loadingToggleAction,
  monthFilter,
  ObjectiveSelected,
  scrollToPoistionTbale
} from '../../redux/actions/categories';
import { filterSoldeByColor } from '../../helpers/filterSoldeByStatus';
import { deleteElement, getonlyElements, modeChart, modeElement } from '../../redux/actions/element';
import { addNewObjectif, deleteObjectif, getAllObjective } from '../../redux/actions/objective';
import * as XLSX from 'xlsx-color';
import { result } from 'lodash';
import { nestCategories } from '../../helpers/helpers';

const Header = ({ sendDataElement }) => {
  const elements = useSelector((state) => state.categories.elements);
  const categories = useSelector((state) => state.categories.categories);
  const dateFilter = useSelector((state) => state.categories.dateFilter);
  const objectives = useSelector((state) => state.categories.objectives);
  const listsSolde = useSelector((state) => state.categories.soldeAll);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  const SelectedObjective = useSelector((state) => state.categories.SelectedObjective);
  const checkedSynthese = useSelector((state) => state.categories.checkedSynthese);
  const checkedObjective = useSelector((state) => state.categories.checkedObjectif);

  const { Option } = Select;
  const path = useLocation();
  let dateNow = new Date();
  const [active, setActive] = useState(false);
  const [modeActive, setModeActive] = useState(false);
  const [mode, setMode] = useState('treso');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [typeEcritureDefault, setTypeEcritureDefault] = useState('');
  const [valueStatut, setValueStatut] = useState();
  const [valueMonth, setValueMonth] = useState();
  const [nameCategory, setNameCategory] = useState('');
  const [idElement, setIdElement] = useState('');
  const [dateElement, setDateElement] = useState('');
  const [dateActive, setDateActive] = useState(false);
  const [startDate, setstartDate] = useState(dateNow.getFullYear() + '-' + 1);
  const [startDateSynthese, setstartDateSynthese] = useState(dateNow.getFullYear() + '-' + 1);
  const [endDate, setendDate] = useState(dateNow.getFullYear() + '-' + 12);
  const [endDateSynthese, setendDateSynthese] = useState(dateNow.getFullYear() + '-' + 12);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [months, setMonths] = useState([
    'January 2023',
    'February 2023',
    'March 2023',
    'April 2023',
    'May 2023',
    'June 2023',
    'July 2023',
    'August 2023',
    'September 2023',
    'October 2023',
    'November 2023',
    'December 2023'
  ]);
  const [size, setSize] = useState('large');
  const [items, setItems] = useState([]);
  const [name, setName] = useState('');
  const inputRef = useRef(null);
  let location = useLocation();
  const dispatch = useDispatch();
  const getStatutFilter = useSelector((state) => state.categories.getStatutFilter);
  const monthFilterGroup = useSelector((state) => state.categories.monthFilter);
  const scrollToTable = useSelector((state) => state.categories.scrollToTable);

  useEffect(() => {
    if (location.pathname == '/') {
      setMode('treso');
    } else if (location.pathname == '/table-compta') {
      setMode('compta');
    } else if (location.pathname == '/categories') {
      setMode('categoriesTreso');
    } else if (location.pathname == '/categories/Compta') {
      setMode('categoriesCompta');
    }
  }, []);
  const checkElement = async () => {
    await elements?.map(async (el) => {
      if (el?.libelle?.length === 0 || el?.montant?.length == 0) {
        setDateElement(el?.dateTreso);
        setIdElement(el.id);
        categories.map((cat) => {
          if (el?.categorieId === cat.id) {
            setNameCategory(cat.name);
          }
        });
        await setIsModalOpen(true);
      } else {
        await setIsModalOpen(false);
      }
    });
  };

  function findMaxDepth(categories, depth = 1) {
    if (!categories || categories.length === 0) {
      return depth;
    }
    let maxDepth = depth;

    for (const category of categories) {
      if (category.children && category.children.length > 0) {
        const childDepth = findMaxDepth(category.children, depth + 1);
        if (childDepth > maxDepth) {
          maxDepth = childDepth;
        }
      }
    }

    return maxDepth;
  }

  function createIndentArray(depth, levelId, name) {
    const indentArray = new Array(depth).fill('');
    if (levelId <= depth) {
      indentArray[levelId - 1] = name;
    }
    return indentArray;
  }
  function createIndentArrayAndFill(depth, levelId, name, syntheseTot) {
    const indentArray = new Array(depth - 1).fill(0);

    for (let i = 0; i < depth - 1; i++) {
      if (i === levelId - 2 && depth !== levelId) {
        indentArray[i] = syntheseTot;
      } else if (i >= levelId - 1) {
        indentArray[i] = name;
      } else if (i === levelId - 2 && depth === levelId) {
        indentArray[i] = name;
      }
    }
    return indentArray;
  }

  function fillFirst(depth, levelId, name) {
    const indentArray = new Array(depth).fill('');
    for (let i = 0; i < depth; i++) {
      indentArray[i] = name;
    }
    return indentArray;
  }

  const addCategoriesToWorksheet = (nestCategories, depth, dataCsv, colsLength) => {
    nestCategories.forEach((category) => {
      let rowData;
      const payloadObjectif = SelectedObjective
        ? category?.objectives?.find((el) => SelectedObjective?.nameObjective === el?.nameObjective)?.Categorie_Objective?.montant
        : 0;

      let total = 0;

      category?.element?.forEach((element, index) => {
        total += element.montant;
      });
      rowData = [
        ...createIndentArray(depth, category?.levelId, category?.name),
        payloadObjectif,
        category?.levelId === 1 ? category?.synthese : 0,
        ...(category?.levelId === 1
          ? fillFirst(depth - 1, category?.levelId, total)
          : createIndentArrayAndFill(depth, category?.levelId, total, category?.synthese))
      ];

      dataCsv.push({ data: rowData, isSpecial: category?.is_special, level: category?.levelId });

      if (category.children && category.children.length > 0) {
        addCategoriesToWorksheet(category.children, depth, dataCsv);
      }
    });
  };

  const normalXlsxColors = ['faa6a6', 'fbbaba', 'fccece', 'fde1e1', 'fef5f5'];
  const specialXlsxColors = ['d9b99b', 'e4d5b7', 'eed9c4', 'fff0db', 'faf0e6'];

  const applyStylesRecursively = (dataWithSpecial, worksheet) => {
    for (let i = 0; i < dataWithSpecial.length; i++) {
      const rowData = dataWithSpecial[i];
      const rowNumber = i + 2;

      rowData.data.forEach((cellData, columnIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: rowNumber, c: columnIndex });
        const borderSettings = {
          top: { style: 'thin', color: { rgb: '111111' } },
          right: { style: 'thin', color: { rgb: '111111' } },
          bottom: { style: 'thin', color: { rgb: '111111' } },
          left: { style: 'thin', color: { rgb: '111111' } }
        };
        const cellStyle = rowData.level
          ? { fill: { fgColor: { rgb: rowData.isSpecial ? specialXlsxColors[rowData.level - 1] : normalXlsxColors[rowData.level - 1] } } }
          : {};
        worksheet[cellAddress] = {
          v: cellData,
          s: { ...cellStyle, border: borderSettings, alignment: { horizontal: typeof cellData === 'string' ? 'left' : 'right' } }
        };
      });
    }
  };

  const fillArraysWithValue = (size, word, isIndexed) =>
    Array.from(Array(size), (_, index) => (typeof word === 'number' ? word : `${word}${isIndexed ? ` ${index + 1}` : ''}`));

  const downloadExcel = () => {
    const categoriesElementsMerged = categories.map((category) => {
      return { ...category, element: elements?.filter((element) => element?.categorieId === category.id) };
    });

    const nestedCategories = nestCategories(categoriesElementsMerged);

    const maxDepth = findMaxDepth(nestedCategories);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([]);

    const dataCSV = [
      {
        data: [
          'solde Total',
          ...Array(maxDepth - 1).fill(''),
          listsSolde?.soldeObjectif ? listsSolde?.soldeObjectif : 0,
          ...fillArraysWithValue(maxDepth, listsSolde?.soldeSynthese, false)
        ],
        isSpecial: false
      }
    ];

    const headerRow = [
      'Name',
      ...Array(maxDepth - 1).fill(''),
      SelectedObjective?.nameObjective ? SelectedObjective?.nameObjective : 'objectif',
      'Synthese',
      ...fillArraysWithValue(maxDepth - 1, 'niveau', true)
    ];
    addCategoriesToWorksheet(nestedCategories, maxDepth, dataCSV, headerRow?.length);

    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: headerRow.length - 1 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: maxDepth - 1 } }
    ];

    worksheet['!merges'] = merges;

    const mode = location.pathname == '/table-compta' ? 'Comptabilite' : 'Tresorerie';

    const finalData = dataCSV.map((el) => el.data);

    XLSX.utils.sheet_add_aoa(worksheet, [[mode], headerRow, ...finalData]);
    applyStylesRecursively(dataCSV, worksheet, 0);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'excelSheet');
    XLSX.writeFile(workbook, 'categories.xlsx');
  };

  const handleClickMode = async (params) => {
    await checkElement();
    const color = filterSoldeByColor('');
    dispatch(colorSoldeTotal(color));
    dispatch(modeElement(params));

    if (params === 'treso') {
      setMode(params);
      dispatch(listCategorie(params, startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, SelectedObjective?.id));
      dispatch(listSolde('treso', startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, SelectedObjective?.id));
      setModeActive(!modeActive);
    } else if (params === 'compta') {
      setMode(params);
      dispatch(listCategorie(params, startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, SelectedObjective?.id));
      dispatch(listSolde('compta', startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, SelectedObjective?.id));
      setModeActive(!modeActive);
    }
  };

  const handleClickActive = () => {
    setActive(!active);
  };

  const showModal = () => {
    checkElement();
    setIsModalVisible(true);
    setDateActive(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsModalVisible(false);
  };

  const handleCancel = async () => {
    if (mode === 'treso') {
      dispatch(deleteElement(idElement, 'Trésorerie'));
    } else if (mode === 'compta') {
      dispatch(deleteElement(idElement, 'Comptabilité'));
    }
    setIsModalOpen(false);
  };

  const sortArrayofArray = (array, number) => {
    let sorted = number;
    let result = [];
    let actuArray = [];
    array.forEach((el, index) => {
      if (sorted > 1) {
        actuArray.push(el);
        sorted--;
      } else {
        actuArray.push(el);
        sorted = number;
        result.push([...actuArray]);
        actuArray = [];
      }
      if (index === array.length - 1 && actuArray.length > 0) {
        result.push([...actuArray]);
      }
    });
    return result;
  };

  const content = () => {
    return (
      <div className="popup-ajouterEcriture">
        <Button
          className="simulation"
          value={'simulation'}
          onClick={(e) => {
            setTypeEcritureDefault('Simulation');
            setValueStatut(1);
            showModal();
          }}>
          DE SIMULATION
        </Button>

        <Button
          className="relle"
          value={'relle'}
          onClick={async (e) => {
            checkElement();
            setTypeEcritureDefault('Relle');
            setValueStatut(2);
            showModal();
          }}>
          RELLE
        </Button>
      </div>
    );
  };

  const handleChangeDate = (value, dateString) => {
    const startDate = moment(dateString[0], 'YYYY-MM');
    const endDate = moment(dateString[1], 'YYYY-MM');
    setstartDate(startDate.format('YYYY-MM'));
    setendDate(endDate.format('YYYY-MM'));
    const monthArray = [];
    let currentMonth = startDate;
    while (currentMonth <= endDate) {
      monthArray.push(currentMonth.format('MMMM YYYY'));
      currentMonth = currentMonth.add(1, 'month');
    }

    if (monthFilterGroup) {
      let resultSorted = sortArrayofArray(monthArray, monthFilterGroup);
      let monthGroup = [];
      resultSorted.forEach((el) => {
        let rst = '';
        el.forEach((sort, index) => {
          if (index === 0) {
            rst = sort;
          } else if (index === el.length - 1) {
            rst += ' - ' + sort;
          }
        });
        monthGroup.push(rst);
      });
      setMonths(monthGroup);
    } else {
      setMonths(monthArray);
    }
    // setMonths(monthArray);
    if (mode === 'treso') {
      dispatch(
        listSolde(
          'treso',
          moment(dateString[0], 'YYYY-MM').format('YYYY-MM'),
          moment(dateString[1], 'YYYY-MM').format('YYYY-MM'),
          '',
          dateFilter?.startDateSynthese ? dateFilter.startDateSynthese : startDateSynthese,
          dateFilter?.endDateSynthese ? dateFilter?.endDateSynthese : endDateSynthese,
          userId
        )
      );
    } else if (mode === 'compta') {
      dispatch(
        listSolde(
          'compta',
          moment(dateString[0], 'YYYY-MM').format('YYYY-MM'),
          moment(dateString[1], 'YYYY-MM').format('YYYY-MM'),
          '',
          dateFilter?.startDateSynthese ? dateFilter.startDateSynthese : startDateSynthese,
          dateFilter?.endDateSynthese ? dateFilter?.endDateSynthese : endDateSynthese,
          userId
        )
      );
    }
  };

  React.useEffect(() => {
    dispatch(
      filterDateAction(
        startDate,
        endDate,
        months,
        dateFilter?.startDateSynthese ? dateFilter.startDateSynthese : startDateSynthese,
        dateFilter?.endDateSynthese ? dateFilter?.endDateSynthese : endDateSynthese
      )
    );
    dispatch(getAllObjective(userId));
  }, [startDate, endDate, months, userId]);

  React.useEffect(() => {
    if (objectives) {
      objectives.forEach((el) => {
        setItems((prevItems) => [...prevItems, el.nameObjective]);
      });
    }
  }, [objectives]);

  const title = <span className="titlePopover">Ajouter une écriture</span>;

  const handleChange = async (value) => {
    const monthArray = [];
    let currentMonth = moment(startDate);
    while (currentMonth <= moment(endDate)) {
      monthArray.push(currentMonth.format('MMMM YYYY'));
      currentMonth = currentMonth.add(1, 'month');
    }
    let resultSorted = sortArrayofArray(monthArray, value);
    let monthNew = [];
    resultSorted.forEach((el) => {
      let rst = '';
      el.forEach((sort, index) => {
        if (index === 0) {
          rst = sort;
        } else if (index === el.length - 1) {
          rst += ' - ' + sort;
        }
      });
      monthNew.push(rst);
    });
    setMonths(monthNew);
    setValueMonth(value);
    await dispatch(monthFilter(value));

    if (mode === 'treso') {
      await dispatch(
        listSolde('treso', startDate, endDate, '', '', '', userId, '', {
          filterSolde: getStatutFilter ? getStatutFilter : ''
        })
      );
    } else if (mode === 'compta') {
      await dispatch(
        listSolde('compta', startDate, endDate, '', '', '', userId, '', {
          filterSolde: getStatutFilter ? getStatutFilter : ''
        })
      );
    }
  };
  const options = [
    { label: 'Deux mois', value: 2 },
    { label: 'Trois mois', value: 3 },
    { label: 'Quatre mois', value: 4 },
    { label: 'Six mois', value: 6 },
    { label: 'Anneé', value: 12 }
  ];
  const onChangeSynthese = (checked) => {
    dispatch(checkedSynthse(checked));
  };

  const onChangeObjectif = (checked) => {
    if (SelectedObjective) {
      dispatch(ObjectiveSelected(SelectedObjective));
    } else {
      dispatch(ObjectiveSelected(objectives[0]));
    }
    dispatch(checkedObjectif(checked));
    dispatch(checkedSynthse(true));

    dispatch(listCategorie(mode, startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, objectives[0]?.id));
    dispatch(listSolde(mode, startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, objectives[0]?.id));
  };

  const addObjectif = () => {
    dispatch(checkedObjectif(true));
  };

  const changeObjective = async (value) => {
    let objectiveSeelected = {};
    await objectives.forEach((el) => {
      if (el.id == value) {
        objectiveSeelected = el;
        return el;
      }
    });
    await dispatch(ObjectiveSelected(objectiveSeelected));
    await dispatch(listCategorie(mode, startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, value));
    await dispatch(listSolde(mode, startDate, endDate, valueMonth, dateFilter.startDateSynthese, dateFilter.endDateSynthese, userId, value));
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    const dataObjectif = {
      nameObjective: name,
      userId: userId
    };
    dispatch(addNewObjectif(dataObjectif, 'treso', dateFilter.startDate, dateFilter.endDate, dateFilter.startDateSynthese, dateFilter.endDateSynthese));

    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const deleteObjective = async (id) => {
    await dispatch(deleteObjectif(id, userId));
    await dispatch(checkedObjectif(false));
    await dispatch(ObjectiveSelected(null));
  };

  const handleClickModeElement = (params) => {
    dispatch(colorSoldeTotal(''));
    dispatch(modeElement(params));
    if (params === 'treso') {
      dispatch(getonlyElements(userId, 'dateTreso', '', '', dateFilter.startDate, dateFilter.endDate));
    } else if (params === 'compta') {
      dispatch(getonlyElements(userId, 'dateCompta', '', '', dateFilter.startDate, dateFilter.endDate));
    }
  };
  const handleClickModeChart = (params) => {
    dispatch(modeChart(params));
  };
  return (
    <>
      <div className="header">
        <div className="header__left">
          <div className="header__left__logo">
            <Link to="/">
              <img src={kwanto} />
            </Link>
          </div>
          <div className="header__left__datePicker">
            <RangePicker
              className="rangePicker"
              picker="month"
              onChange={handleChangeDate}
              defaultValue={[dayjs(startDate, 'YYYY-MM-DD'), dayjs(endDate, 'YYYY-MM-DD')]}
            />
          </div>
          <div className="selectGroupCompact">
            {!path.pathname.includes('categories') && !path.pathname.includes('chart') && (
              <Select
                size={size}
                optionLabelProp="label"
                allowClear={true}
                onChange={handleChange}
                style={{
                  width: 35
                }}
                options={options.map((item) => ({
                  value: item.value,
                  label: item.label
                }))}
                bordered
                placement="bottomRight"
                dropdownMatchSelectWidth={false}
              />
            )}
          </div>
          <div className="switchHeader">
            {!path.pathname.includes(['categories']) && !path.pathname.includes('chart') ? (
              <div className="switch">
                <div className="syntheseSwitch">
                  <span>SYNTHESE</span>
                  <Switch onChange={onChangeSynthese} checked={checkedSynthese ? true : false} />
                </div>
                {objectives && objectives.length === 0 ? (
                  <div className="objectifSwitch" onClick={() => addObjectif()}>
                    <span>Créer un nouveau Objectif + </span>
                  </div>
                ) : (
                  objectives && (
                    <Select
                      className="selectObjective"
                      defaultValue={objectives[0]?.nameObjective}
                      style={{
                        width: 200
                      }}
                      onChange={changeObjective}
                      size={size}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: '8px 0'
                            }}
                          />
                          <Space
                            style={{
                              padding: '0 8px 4px'
                            }}>
                            <Input placeholder="Objectif" ref={inputRef} value={name} onChange={onNameChange} />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                              Ajouter
                            </Button>
                          </Space>
                        </>
                      )}>
                      {objectives?.map((item) => (
                        <Option key={item.id} value={item.id} label={item.nameObjective}>
                          {item.nameObjective}
                          <a
                            style={{ float: 'right' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteObjective(item.id); // pass the selected option to delete
                            }}>
                            <DeleteOutlined />
                          </a>
                        </Option>
                      ))}
                    </Select>
                  )
                )}
                {objectives && objectives.length > 0 && (
                  <div>
                    <Switch onChange={onChangeObjectif} checked={checkedObjective ? true : false} />
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {!path.pathname.includes(['categories']) && !path.pathname.includes(['chart']) && checkedSynthese && (
            <div>
              <img onClick={downloadExcel} src={exportTable} />
            </div>
          )}
        </div>
        {path.pathname.includes('chart') ? (
          <div className="header__right__mode">
            <div className="header__right__mode__treso">
              <Tooltip placement="bottomLeft" title={'Mode trésorerie'}>
                <img src={modeTreso} onClick={() => handleClickModeChart('treso')} />
              </Tooltip>
            </div>
            <div className="header__right__mode__compta">
              <Tooltip placement="bottomLeft" title={'Mode Comptabilité'}>
                <img src={modeCompta} onClick={() => handleClickModeChart('compta')} />
              </Tooltip>
            </div>
          </div>
        ) : path.pathname.includes('categories') ? (
          <div className="header__right__mode">
            <div className="header__right__mode__treso">
              <Tooltip placement="bottomLeft" title={'Mode trésorerie'}>
                <img src={modeTreso} onClick={() => handleClickModeElement('treso')} />
              </Tooltip>
            </div>
            <div className="header__right__mode__compta">
              <Tooltip placement="bottomLeft" title={'Mode Comptabilité'}>
                <img src={modeCompta} onClick={() => handleClickModeElement('compta')} />
              </Tooltip>
            </div>
          </div>
        ) : (
          <div className="header__right__mode">
            <div className="header__right__mode__treso">
              <Tooltip placement="bottomLeft" title={'Mode trésorerie'}>
                <Link to="/" onClick={() => handleClickMode('treso')}>
                  <img src={modeTreso} />
                </Link>
              </Tooltip>
            </div>
            <div className="header__right__mode__compta">
              <Tooltip placement="bottomLeft" title={'Mode Comptabilité'}>
                <Link to="/table-compta" onClick={() => handleClickMode('compta')}>
                  <img src={modeCompta} />
                </Link>
              </Tooltip>
            </div>
          </div>
        )}
        {!path.pathname.includes('chart') && (
          <div className="header_right_add-element">
            <Popover
              overlayClassName="popover-add-element"
              overlayStyle={{ paddingTop: '18px', paddingLeft: '6px' }}
              placement="bottomLeft"
              title={title}
              content={content}>
              <img src={add} />
            </Popover>
            {isModalVisible && !isModalOpen && (
              <PopupCustomer
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                typeEcritureDefault={typeEcritureDefault}
                valueStatut={valueStatut}
                sendDataElement={sendDataElement}
                mode={mode}
                dateActive={dateActive}
                months={months}
              />
            )}
          </div>
        )}

        <div className={active ? 'header__right__filterActive' : null} onClick={handleClickActive}>
          <img src={filter} />
          {active && !modeActive && (
            <div className="header__right__filterActive__modeFilterActiveTreso">
              <img src={prev} />
              <img src={engage} />
              <img src={pointe} />
            </div>
          )}
          {active && modeActive && (
            <div className="header__right__filterActive__modeFilterActiveCompta">
              <img src={simulation} />
              <img src={reel} />
            </div>
          )}
        </div>
        <div className="header__right__InputSearch">
          <InputSearch />
        </div>
        <div className="header__right__person">
          <img src={person} />
        </div>
      </div>
      {isModalOpen && (
        <Modal
          footer={[
            <div key="Submit" className="footer">
              <div key="keyConfirmation" className="btn_Confirmation">
                <Button key="annuler" onClick={() => handleCancel()} className="annuler">
                  Annuler
                </Button>
                <Button key="valider" className="valider" onClick={() => handleOk()}>
                  Valider
                </Button>
              </div>
            </div>
          ]}
          title="Terminez la création"
          open={isModalOpen}>
          <p>
            Il y a un élément dans la catégorie: <span>{nameCategory}</span> et dans la date: <span>{dateElement}</span> qui attend pour finir la création.
          </p>

          <p>Cliquez sur valider pour terminer la création ou cliquez sur Annuler pour supprimer l&apos;élément.</p>
        </Modal>
      )}
    </>
  );
};

export default Header;
